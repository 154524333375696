import React, { useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ListItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import commonStyle from '../../styles/commonStyle';
import menuStyle from './menuStyle';
import { connect } from 'react-redux';


const MenuItemRoot = (props:any) => {
    const { name, Icon, items = [] } = props
    const {classes:commonClasses}=commonStyle();
    const {classes:menuClasses}=menuStyle();

    const isExpandable:boolean = items && items.length > 0
    const [open, setOpen] = useState(false)
    function handleClick():void{
        setOpen(!open)
    }
    return (
        <ListItemButton onClick={props.handleClick} className={commonClasses.padding0}>
            
            <ListItem 
            component={Link}
            to={`/${props.workplace}${props.link}`}
            disableGutters={true}
            // style={{padding:'5px 10px'}}
            className={menuClasses.menuLink}
            >
                {/* Display an icon if any */}
            {!!Icon && (
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
            )}
                {/* <ListItemText inset={!Icon}  > */}
               <Typography style={{color:"#fff",marginLeft:"10px"}} className={commonClasses.whiteSpaceNoWrap}>{name}</Typography> 
            {/* </ListItemText>  */}
            </ListItem>
            {/* Display the expand menu if the item has children */}
            {isExpandable && !open && <ExpandMoreIcon className={menuClasses.menuExpandIcon} />}
            {isExpandable && open && <ExpandLessIcon className={menuClasses.menuExpandIcon} />}
        </ListItemButton>
    );
};

const mapStateToProps = (state: any) => ({
    isAuthenticatedUser: state.isAuthenticatedUser,
    workplace: state.workplace
});
const mapDispatchToProps = (dispatch: any) => ({
    setIsAuthenticated: (isAuthenticatedUser: boolean, workplace: string | undefined) => dispatch({ type: 'SET_AUTHENTICATED', payload: { isAuthenticatedUser: isAuthenticatedUser, workplace: workplace } }),
});
export default connect(mapStateToProps, mapDispatchToProps)(MenuItemRoot);