import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const Verify = () => {
    const navigate=useNavigate()
    function redirectToLogin() {
        navigate('/',{ replace: true })
    }
    return (
        <Box className='positiomAbsCenter' sx={{textAlign: "center",
    "background": "#fff",
    "padding": "42px",
    width:"50%",
    margin:"auto"}}>
            <Box>
 <CheckCircleIcon color='success' sx={{ fontSize: 60 }} />
            <Typography variant="h5">
                You have successfully verifed account 
            </Typography>
            </Box>
           
            <Button variant="contained" onClick={redirectToLogin} sx={{ marginTop: "32px" }}>Login</Button>
        </Box>
    );
};

export default Verify;
