import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { CommonCardWithIcon } from "../common/CommonCardWithIcon";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import People from "@mui/icons-material/People";
import Person from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import { CardHOC } from "../hocs/CardHOC";
import commonStyle from "../../styles/commonStyle";
import LinearProgressWithLabel from "../common/LinerProgressWithLabel/LinerProgressWithLabel";
import { PieCharts } from "../common/Charts/PieCharts";
import { BarChart } from "../common/Charts/BarChart";
import dashboardServices from "../../services/dashboardServices";
import { connect } from "react-redux";
type Props = {};
type cardsProps = { color: string; title: string; number: number; icon: any,isPlatformAdmin:boolean;access:string };
const Dashboard = (props: any) => {
  const { classes: commonClasses, cx } = commonStyle();
  const [cardsData, setcardsData] = useState<cardsProps | any>([]);
  const [expDocsData, setExpDocsData] = useState<any>([]);
  const [docsCounts, setdocsCounts] = useState<any>([]);
  const [totalDocs, setTotalDocs] = useState(0);

  useEffect(() => {
    if(props.isPlatformAdmin){
       fetchAdminDashboardCouts();
    fetchAdminExpiryDocs();
    fetchAdminDocumentsCounts();
    }else{
       fetchDashboardCouts();
    fetchExpiryDocs();
    fetchDocumentsCounts();
    }
   
  }, []);

  function fetchAdminDocumentsCounts() {
    dashboardServices
      .getAdminDocumentCounts()
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          if (resp.data.data.getAdminDocumentCounts.rows.length > 0) {
            setdocsCounts(resp.data.data.getAdminDocumentCounts.rows);
            let totalDoc = resp.data.data.getAdminDocumentCounts.rows.reduce(
              (total: number, rows: any) => {
                return total + Math.round(rows.count);
              },
              0
            );
            setTotalDocs(totalDoc);
          } else {
            setdocsCounts([]);
            setTotalDocs(0);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function fetchAdminExpiryDocs() {
    dashboardServices
      .getAdminDocumentExpiryCategory()
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          if (resp.data.data.getAdminDocumentExpiryCategory.rows.length > 0) {
            setExpDocsData(resp.data.data.getAdminDocumentExpiryCategory.rows);
          } else {
            setExpDocsData([]);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function fetchAdminDashboardCouts() {
    dashboardServices
      .getAdminDashboardCounts()
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          if (resp.data.data.getAdminDashboardCounts.rows.length > 0) {
            let temp = resp.data.data.getAdminDashboardCounts.rows;

            setcardsData([
              {
                color: "#FA2600",
                title: "Workplaces",
                number: temp.find((item: any) => item.type === "Workplaces")
                  ?.count,
                icon: Person,
              },
              {
                color: "#3227B7",
                title: "Users",
                number: temp.find((item: any) => item.type === "Users")?.count,
                icon: People,
              },
              {
                color: "#FEB104",
                title: "Employee",
                number: temp.find((item: any) => item.type === "Employees")
                  ?.count,
                icon: GroupsIcon,
              },
            ]);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

   function fetchDocumentsCounts() {
    dashboardServices
      .getDashboardCounts(props.workplaceid)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          if (resp.data.data.getMemberDashboardCounts.rows.length > 0) {
            setdocsCounts(resp.data.data.getMemberDashboardCounts.rows);
            let totalDoc = resp.data.data.getMemberDashboardCounts.rows.reduce(
              (total: number, rows: any) => {
                return total + Math.round(rows.count);
              },
              0
            );
            setTotalDocs(totalDoc);
          } else {
            setdocsCounts([]);
            setTotalDocs(0);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function fetchExpiryDocs() {
    dashboardServices
      .getDocumentExpiryCategory(props.workplaceid)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          if (resp.data.data.getMemberDocumentExpiryCategory.rows.length > 0) {
            setExpDocsData(resp.data.data.getMemberDocumentExpiryCategory.rows);
          } else {
            setExpDocsData([]);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function fetchDashboardCouts() {
    dashboardServices
      .getDashboardCounts(props.workplaceid)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          if (resp.data.data.getMemberDashboardCounts.rows.length > 0) {
            let temp = resp.data.data.getMemberDashboardCounts.rows;

            setcardsData([
              {
                color: "#FA2600",
                title: "Workplaces",
                number: temp.find((item: any) => item.type === "Workplaces")
                  ?.count,
                icon: Person,
                access:"PA"
              },
              {
                color: "#3227B7",
                title: "Users",
                number: temp.find((item: any) => item.type === "Users")?.count,
                icon: People,
                access:"A"
              },
              {
                color: "#FEB104",
                title: "Employee",
                number: temp.find((item: any) => item.type === "Employees")
                  ?.count,
                icon: GroupsIcon,
                access:"A"
              },
            ]);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getDocPer(name: string) {
    let temp = docsCounts?.find((item: any) => item.category_name.toLocaleLowerCase() === name.toLocaleLowerCase());
    if (temp !== undefined) {
      return (temp.count / totalDocs) * 100;
    }
    return 0;
  }

//   function getStatCard(item:any,index:number) {
//     if(item.access==='PA'){
//       if(props.isPlatformAdmin){
// return <Grid item xs={12} md={4} xl={4} key={`card-${index}`}>
//                   <CommonCardWithIcon {...item} />
//                 </Grid>
//       }
//       return ''
      
//     }
//     return <Grid item xs={12} md={4} xl={4} key={`card-${index}`}>
//                   <CommonCardWithIcon {...item} />
//                 </Grid>
//   }
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} xl={9}>
          <Grid container spacing={2}>
            {cardsData?.map((item: cardsProps, index: number) => {
              return (
                <Grid item xs={12} md={4} xl={4} key={`card-${index}`}>
                  <CommonCardWithIcon {...item} />
                </Grid>
                
              );
            })}
          </Grid>
          <Grid container sx={{ width: "100%" }}>
            <Grid item xs={12} md={12} xl={12}>
              <Box sx={{ marginTop: "32px", width: "100%" }}>
                <CardHOC
                  classes={cx(
                    commonClasses.padding16,
                    commonClasses.paddingBottom32,
                    commonClasses.fullWidth
                  )}
                >
                  <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
                    Documents to be Expired
                  </Typography>
                  <Box sx={{ marginTop: "32px" }}>
                    <BarChart data={expDocsData} />
                  </Box>
                </CardHOC>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} md={3} xl={3}>
          <CardHOC
            classes={cx(commonClasses.padding16, commonClasses.paddingBottom32)}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
              Total Documents: {totalDocs}
            </Typography>
            <Box sx={{ marginTop: "32px" }}>
              <LinearProgressWithLabel
                title="Passport"
                percentage={getDocPer("Passport")}
                colorValue="#FFB200"
              />
            </Box>
            <Box sx={{ marginTop: "32px" }}>
              <LinearProgressWithLabel
                title="Medical Insurance"
                percentage={getDocPer("Medical Insurance")}
                colorValue="#4339F2"
              />
            </Box>
            <Box sx={{ marginTop: "32px" }}>
              <LinearProgressWithLabel
                title="ID card"
                percentage={getDocPer("ID card")}
                colorValue="#02A0FC"
              />
            </Box>
          </CardHOC>
          <Box sx={{ marginTop: "32px" }}>
            <CardHOC
              classes={cx(
                commonClasses.padding16,
                commonClasses.paddingBottom32
              )}
            >
              <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
                Documents
              </Typography>
              <Box sx={{ marginTop: "32px" }}>
                {docsCounts.length > 0 && (
                  <PieCharts data={docsCounts} action={getDocPer} />
                )}
              </Box>
            </CardHOC>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  isPlatformAdmin: state.isPlatformAdmin,
  workplaceid:state.workplaceid
});
export default connect(mapStateToProps)(Dashboard);
