import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
export const options = {
  plugins: {
    title: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      title: {
        display: true,
        text: "Months",
      },
      stacked: true,
      grid: {
        display: false, // show/hide grid line in x-axis
      },
    },
    y: {
      title: {
        display: true,
        text: "Number of documents",
      },
      stacked: true,
      grid: {
        display: false, // show/hide grid line in x-axis
      },
    },
  },
};
export const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const BarChart = (props: any) => {
  const data = {
    labels,

    datasets: [
      {
        label: "Passport",
        data: labels.map((m: string) => {
          let temp = props?.data.find(
            (item: any) =>
              item.month_name.includes(m) && item.document_type === "Passport"
          );
          return temp === undefined ? 0 : temp.count;
        }),
        backgroundColor: "#FFB200",
        barThickness: 14,
        borderRadius: 100,
      },
      {
        label: "Insurence",
        data: labels.map((m: string) => {
          let temp = props?.data.find(
            (item: any) =>
              item.month_name.includes(m) &&
              item.document_type === "Medical Insurance"
          );
          return temp === undefined ? 0 : temp.count;
        }),
        backgroundColor: "#4339F2",
        barThickness: 14,
        borderRadius: 100,
      },
      {
        label: "ID cards",
        data: labels.map((m: string) => {
          let temp = props?.data.find(
            (item: any) =>
              item.month_name.includes(m) && item.document_type === "ID Card"
          );
          return temp === undefined ? 0 : temp.count;
        }),
        backgroundColor: "#02A0FC",
        barThickness: 14,
        borderRadius: 100,
      },
    ],
  };
  return (
    <div>{props.data.length > 0 && <Bar options={options} data={data} />}</div>
  );
};
