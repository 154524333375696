import React from "react";
import Avatar from "@mui/material/Avatar";
import userImage from "../../assets/images/3.jpg";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import { Box, Divider, MenuItem } from "@mui/material";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

const HeaderUserInfo = (props: any) => {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogOut() {
    cookies.remove("isAuthenticatedUser");
    localStorage.removeItem("userinfo");
    localStorage.removeItem("persist:root");
    props.setIsAuthenticated(false);
    navigate("/signin");
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box sx={{display:"flex",alignItems:'center',justifyContent:"space-between",padding:"0 16px",background:"#ffffff", height:"65px"}}>
      <div></div>
      <div>
        <span aria-describedby={id} onClick={handleClick}>
          <AccountCircleIcon
            style={{ width: "40px", height: "40px", cursor: "pointer" }}
          />
        </span>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem sx={{borderBottom:"1px solid #ccc"}}>
          {props.userInfo.user_name}
          </MenuItem>
         
          <MenuItem onClick={handleClose}>
            <Link to={`profile/${props.userInfo.defaultWorkplace.id}/${props.userInfo.id}`}>Profile</Link>
          </MenuItem>
          {
            props.isPlatformAdmin && 
          
          <MenuItem onClick={handleClose}>
            <Link to={`createworkplace`}>
              Create Workplace
            </Link>
          </MenuItem>
          }
          <MenuItem onClick={handleClose}>
            <span onClick={handleLogOut}>Logout</span>
          </MenuItem>
        </Popover>
      </div>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthenticatedUser: state.isAuthenticatedUser,
  workplace: state.workplace,
  isPlatformAdmin:state.isPlatformAdmin,
  userInfo:state.userInfo
});
const mapDispatchToProps = (dispatch: any) => ({
  setIsAuthenticated: (
    isAuthenticatedUser: boolean,
    workplace: string | undefined
  ) =>
    dispatch({
      type: "SET_AUTHENTICATED",
      payload: {
        isAuthenticatedUser: isAuthenticatedUser,
        workplace: workplace,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserInfo);
