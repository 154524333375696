// @flow
import { Card } from "@mui/material";
import * as React from "react";
type Props = {
    children:string | JSX.Element | JSX.Element[],
    classes?:any
};
export const CardHOC = (props: Props) => {
  return <Card className={props.classes} sx={{padding:"24px 32px"}}>
    {props.children}
  </Card>;
};
