import React, { useEffect, useState } from "react";
import {
  IconButton,
  Tooltip,
  TextField,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import CommonDataTable from "../common/CommonDataTable";
import { format } from "date-fns";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import workspaceServices from "../../services/workspaceServices";
import withRouter from "../../containers/withRouter";
import DocumentAction from "./DocumentAction";
import { connect } from "react-redux";
import CommonDialog from "../common/CommonDialog";
import documentServices from "../../services/documentServices";

const ViewDocuments = (props: any) => {
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const [gridLoading, setGridLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDocID, setDeleteDocID] = useState(0);
  const [openPopUp, setOpenPopUp] = useState(false);
  const open = Boolean(anchorEl);
  const empid = props?.params?.empid;
  const workplace_id = props?.params?.id;
  useEffect(() => {
    getDocumentList();
  }, []);

  const navigate = useNavigate();
  const columns = [
    {
      name: "Document Name",
      cell: (item: any) => <span>{item.document_name}</span>,
      width: "15%",
    },
    {
      name: "Document Number",
      cell: (item: any) => <span>{item.document_unique_number}</span>,
      width: "15%",
    },
    {
      name: "Issued date",
      cell: (item: any) => (
        <span>
          {item?.document_issue_date !== null
            ? format(new Date(item?.document_issue_date), "yyyy-MM-dd hh:mm a")
            : ""}
        </span>
      ),
      width: "15%",
    },
    {
      name: "Expiry date",
      cell: (item: any) => (
        <span>
          {item?.document_exp_date !== null
            ? format(new Date(item?.document_exp_date), "yyyy-MM-dd hh:mm a")
            : ""}
        </span>
      ),
      width: "15%",
    },
    {
      name: "Created date",
      cell: (item: any) => (
        <span>
          {format(new Date(item?.created_datetime), "yyyy-MM-dd hh:mm a")}
        </span>
      ),
      width: "15%",
    },
    {
      name: "Created date",
      cell: (item: any) => (
        <span>
          {format(new Date(item?.updated_datetime), "yyyy-MM-dd hh:mm a")}
        </span>
      ),
      width: "15%",
    },
    {
      name: "Action",
      cell: (item: any) => (
        <DocumentAction item={item} deleteDocument={deleteDocument} />
      ),
      width: "10%",
    },
  ];
  function deleteDocument(id: number) {
    // console.log("id", id);
    setDeleteDocID(id);
    setOpenPopUp(true);
  }
  function getDocumentList() {
    const req = {
      id: workplace_id,
      empid: empid,
      perPage: perPage,
      page: page,
    };
    apiCall(req);
  }
  function apiCall(req: any) {
    setGridLoading(true);
    workspaceServices
      .getWorkPlaceDocuments(req)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          setData(resp.data.data.getWorkPlaceDocuments.rows);
          setTotalRecords(resp.data.data.getWorkPlaceDocuments.totalCount);
        } else {
          setData([]);
          setTotalRecords(0);
        }
        setGridLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setGridLoading(false);
      });
  }
  function handleChangePage(pageNumber: number) {
    const req = {
      id: workplace_id,
      empid: empid,
      perPage: perPage,
      page: pageNumber,
    };
    apiCall(req);
  }
  function handleChangeRowsPerPage(rowCount: number) {
    setPage(1);
    setPerPage(rowCount);

    const req = {
      id: workplace_id,
      empid: empid,
      perPage: rowCount,
      page: page,
    };
    apiCall(req);
  }
  function handleDeleteDoc() {
    let req = {
      id: deleteDocID,
      workplace_id: workplace_id,
      object_id: empid,
    };
    documentServices
      .deleteDoument(req)
      .then((resp) => {
        if (resp.data.statusCode === 201) {
          props.openSnackBar({ message: "Document deleted successfully" });
          setOpenPopUp(false);
          getDocumentList();
        } else {
          props.openSnackBar({
            message: "Something went wrong! please try again",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        props.openSnackBar({
          message: "Something went wrong! please try again",
          severity: "error",
        });
      });
  }
  return (
    <Box sx={{ width: "100%" }}>
      {!gridLoading && (
        <CommonDataTable
          columns={columns}
          data={data}
          title="Workplace Employee List"
          perPage={perPage}
          page={page}
          totalRecords={totalRecords}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          placeholder="Search by Document Number"
        />
      )}
      {openPopUp && (
        <CommonDialog
          title="Delete document"
          showCancelBtn={true}
          cancelButtonName={"No"}
          showActionBtn={true}
          actionButtonName="Yes"
          cancelHandler={() => setOpenPopUp(false)}
          actionHandler={handleDeleteDoc}
        >
          Are you sure you want to delete this document?
        </CommonDialog>
      )}
    </Box>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  openSnackBar: (state: {
    message: string;
    duration: number | undefined;
    severity: string;
  }) =>
    dispatch({
      type: "OPEN_SNACKBAR",
      payload: {
        message: state.message,
        duration: state.duration,
        severity: state.severity,
      },
    }),
});
export default connect(null, mapDispatchToProps)(withRouter(ViewDocuments));
