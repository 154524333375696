import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import commonStyle from '../../styles/commonStyle';
import { useNavigate } from 'react-router-dom';
import workspaceServices from '../../services/workspaceServices';
const Actions = (props:any) => {
    const { classes: commonClasses, cx } = commonStyle()
    const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

    const {item}=props;
    function handleCreateUser(id:number) {
        navigate(`/${props.workplace}/createuser/${id}`, { replace: true })
    }
function handleCreateEmployee(id:number) {
    navigate(`/${props.workplace}/createemployee/${id}`, { replace: true })

}
 const handleClose = () => {
    setAnchorEl(null);
  };
    function deleteWorkPlace(id:number) {
      
        props.handleDelete(id)
        
    }
    
     const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const ITEM_HEIGHT = 48;

    return ( 
        <span>

            <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
<Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
          <MenuItem onClick={handleClose}>
            <span onClick={()=>handleCreateUser(item.id)}>
             Add User
             </span>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <span onClick={()=>handleCreateEmployee(item.id)}>
             Add Employee
             </span>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <span onClick={()=>deleteWorkPlace(item.id)}>
             Delete
             </span>
          </MenuItem>
      </Menu>
                
            </span>
     );
}
 
export default Actions;
