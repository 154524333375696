import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { manropeBold, manropeLight, manropeMedium, manropeRegular, manropeSemiBold } from './fontThem';
// A custom theme for this app
const theme = createTheme({
  components: {
    MuiMenuItem:{
styleOverrides: {
      root: {
        fontSize: "14px",
        color:"#008419"
      },
    }
    },
    MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 40,
      },
    }
      
    },
    MuiTypography: {
    styleOverrides: {

        root: {
            color: "#000000",
            fontFamily:"Manrope",

        },
        subtitle1: {
            fontSize: "11px",
            fontWeight: "800",
            color: "#000000"
        },
        body1: {
            fontSize: "14px",

        }
    }
},
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        '@font-face': [manropeLight, manropeRegular, manropeBold, manropeMedium, manropeSemiBold],

        body: {
          root:{
            backgroundColor:'#000'

          }
        }
      }),
    },
  },
  palette: {
    primary: {
      main: '#008419',
    },
    secondary: {
      main: '#000000',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
