import axios from "axios";
import {
    BASE_API_URL
} from "./Env";

const dashboardServices={
getAdminDashboardCounts,
getAdminDocumentExpiryCategory,
getAdminDocumentCounts,
getDashboardCounts,
getDocumentExpiryCategory,
getDocumentCounts
}

async function getAdminDashboardCounts() {
    return await axios({

        // Endpoint to send files
        url: `${BASE_API_URL}/platform/documents/query/dashboard/getAdminDashboardCounts`,
        method: "GET",
        headers: { 'x-auth-token': localStorage.getItem('token') }

    }).then((res) => {
        return res
    }).catch((err) => {
        return err
    });
}
async function getAdminDocumentCounts() {
    return await axios({

        // Endpoint to send files
        url: `${BASE_API_URL}/platform/documents/query/dashboard/getAdminDocumentCounts`,
        method: "GET",
        headers: { 'x-auth-token': localStorage.getItem('token') }

    }).then((res) => {
        return res
    }).catch((err) => {
        return err
    });
}
async function getAdminDocumentExpiryCategory() {
    return await axios({

        // Endpoint to send files
        url: `${BASE_API_URL}/platform/documents/query/dashboard/getAdminDocumentExpiryCategory`,
        method: "GET",
        headers: { 'x-auth-token': localStorage.getItem('token') }

    }).then((res) => {
        return res
    }).catch((err) => {
        return err
    });
}



async function getDashboardCounts(id) {
    return await axios({

        // Endpoint to send files
        url: `${BASE_API_URL}/platform/documents/query/dashboard/getMemberDashboardCounts?limit=100&page=1&workplace_id=${id}`,
        method: "GET",
        headers: { 'x-auth-token': localStorage.getItem('token') }

    }).then((res) => {
        return res
    }).catch((err) => {
        return err
    });
}
async function getDocumentCounts(id) {
    return await axios({

        // Endpoint to send files
        url: `${BASE_API_URL}/platform/documents/query/dashboard/getMemberDocumentCounts?limit=100&page=1&workplace_id=${id}`,
        method: "GET",
        headers: { 'x-auth-token': localStorage.getItem('token') }

    }).then((res) => {
        return res
    }).catch((err) => {
        return err
    });
}
async function getDocumentExpiryCategory(id) {
    return await axios({

        // Endpoint to send files
        url: `${BASE_API_URL}/platform/documents/query/dashboard/getMemberDocumentExpiryCategory?workplace_id=${id}`,
        method: "GET",
        headers: { 'x-auth-token': localStorage.getItem('token') }

    }).then((res) => {
        return res
    }).catch((err) => {
        return err
    });
}

export default dashboardServices;
