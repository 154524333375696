import axios from "axios";
import { BASE_API_URL } from "./Env";

const workspaceServices = {
  getWorkSpace,
  createWorkPlace,
  createWorkPlaceUser,
  updateWorkPlaceUser,
  getWorkPlaceUsers,
  createWorkPlaceEmployee,
  getWorkPlaceEmployees,
  getWorkPlaceEmployeesByID,
  getWorkPlaceDocumentCategories,
  deleteWorkPlace,
  getWorkPlaceDocuments,
  createDocumentCategories,
  getWorkplaceUserByID,
  getWorkplaceUserByIDNew,
  updateUser,
  deleteWorkPlaceUser,
  updateWorkPlaceEmployee,
  deleteWorkPlaceEmployee
};

async function getWorkSpace(req: any) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents/query/workplace/getWorkPlaces?limit=${req.perPage}&page=${req.page}`,
    method: "GET",
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function createWorkPlace(req: { org_name: string; org_domain: string }) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents`,
    data: [
      {
        workplace: {
          crud: "C",
          data: [
            {
              org_name: req.org_name,
              org_domain: req.org_domain,
            },
          ],
        },
      },
    ],
    method: "POST",
    params: { "x-auth-token": localStorage.getItem("token") },
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function deleteWorkPlace(id: number) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents`,
    data: [
      {
        workplace: {
          crud: "D",
          data: [
            {
              id: id,
            },
          ],
        },
      },
    ],
    method: "POST",
    params: { "x-auth-token": localStorage.getItem("token") },
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function createWorkPlaceUser(req: {
  email_id: string;
  password: string;
  workplace_id: string;
  workplace_role: string;
}) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents`,
    data: [
      {
        auth: {
          crud: "C",
          data: [
            {
              workplace_id: req.workplace_id,
              workplace_role: req.workplace_role,
              email_id: req.email_id,
              password: req.password,
            },
          ],
        },
      },
    ],
    method: "POST",
    params: { "x-auth-token": localStorage.getItem("token") },
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
async function updateWorkPlaceUser(req:any) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents`,
    data: [
      {
        auth: {
          crud: "U",
          data: [
            {
              workplace_id: req.workplace_id,
              workplace_role: req.workplace_role,
              email_id: req.email_id,
              password: req.password,
              id:req.id,
              user_name:req.username
            },
          ],
        },
      },
    ],
    method: "POST",
    params: { "x-auth-token": localStorage.getItem("token") },
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
async function deleteWorkPlaceUser(req: {
  
  workplace_id: string;
  id:number;
}) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents`,
    data: [
      {
        auth: {
          crud: "D",
          data: [
            {
              workplace_id: req.workplace_id,
              id:req.id,
            },
          ],
        },
      },
    ],
    method: "POST",
    params: { "x-auth-token": localStorage.getItem("token") },
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
async function getWorkPlaceUsers(req: any) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents/query/workplace/getWorkPlaceUsers?workplace_id=${req.id}&limit=${req.perPage}&page=${req.page}`,
    method: "GET",
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function getWorkplaceUserByID(req: any) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents/query/workplace/getWorkPlaceUsers?workplace_id=${req.id}&limit=${req.perPage}&page=${req.page}&id=${req.userid}`,
    method: "GET",
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function getWorkplaceUserByIDNew(req: any) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents/query/userProfile/getUserProfile?workplace_id=${req.id}&id=${req.userid}`,
    method: "GET",
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function updateUser(req:any) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents`,
    data: [
      {
        auth: {
          crud: "U",
          data: [
            {
             ...req
            },
          ],
        },
      },
    ],
    method: "POST",
    params: { "x-auth-token": localStorage.getItem("token") },
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
type NewEmployee = {
  workplace_id: number;
  employee_gender: string;
  employee_salutation: string;
  employee_first_name: string;
  employee_middle_name: string | undefined | null;
  employee_last_name: string;
  employee_email: string;
  employee_alt_email: string;
  employee_mobile_number: string;
  employee_alt_mobile_number: string;
  employee_country: string;
  employee_country_code: string;
  employee_designation: string;
  employee_permanent_address: string;
  employee_current_primary_address: string;
  employee_current_alt_address: string;
  id?:number|undefined;
};
async function createWorkPlaceEmployee(req: NewEmployee) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents`,
    data: [
      {
        employee: {
          crud: "C",
          data: [
            {
              workplace_id: req.workplace_id,
              employee_gender: req.employee_gender,
              employee_salutation: req.employee_salutation,
              employee_first_name: req.employee_first_name,
              employee_middle_name: req.employee_middle_name,
              employee_last_name: req.employee_last_name,
              employee_email: req.employee_email,
              employee_alt_email: req.employee_alt_email,
              employee_mobile_number: req.employee_mobile_number,
              employee_alt_mobile_number: req.employee_alt_mobile_number,
              employee_country: req.employee_country,
              employee_country_code: req.employee_country_code,
              employee_designation: req.employee_designation,
              employee_permanent_address: req.employee_permanent_address,
              employee_current_primary_address:
                req.employee_current_primary_address,
              employee_current_alt_address: req.employee_current_alt_address,
            },
          ],
        },
      },
    ],
    method: "POST",
    params: { "x-auth-token": localStorage.getItem("token") },
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function updateWorkPlaceEmployee(req: NewEmployee) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents`,
    data: [
      {
        employee: {
          crud: "U",
          data: [
            {
              workplace_id: req.workplace_id,
              employee_gender: req.employee_gender,
              employee_salutation: req.employee_salutation,
              employee_first_name: req.employee_first_name,
              employee_middle_name: req.employee_middle_name,
              employee_last_name: req.employee_last_name,
              employee_email: req.employee_email,
              employee_alt_email: req.employee_alt_email,
              employee_mobile_number: req.employee_mobile_number,
              employee_alt_mobile_number: req.employee_alt_mobile_number,
              employee_country: req.employee_country,
              employee_country_code: req.employee_country_code,
              employee_designation: req.employee_designation,
              employee_permanent_address: req.employee_permanent_address,
              employee_current_primary_address:
                req.employee_current_primary_address,
              employee_current_alt_address: req.employee_current_alt_address,
              id:req.id
            },
          ],
        },
      },
    ],
    method: "POST",
    params: { "x-auth-token": localStorage.getItem("token") },
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function deleteWorkPlaceEmployee(req:{workplace_id:number,id:number}) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents`,
    data: [
      {
        employee: {
          crud: "D",
          data: [
            {
              workplace_id: req.workplace_id,
              id:req.id
            },
          ],
        },
      },
    ],
    method: "POST",
    params: { "x-auth-token": localStorage.getItem("token") },
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}



async function getWorkPlaceEmployees(req: any) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents/query/workplace/getWorkPlaceEmployees?workplace_id=${req.id}&limit=${req.perPage}&page=${req.page}`,
    method: "GET",
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function getWorkPlaceEmployeesByID(req: any) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents/query/workplace/getWorkPlaceEmployees?workplace_id=${req.id}&limit=1&page=1&id=${req.empid}`,
    method: "GET",
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function getWorkPlaceDocumentCategories(req: any) {
  const { id, perPage = 10, page = 1 } = req;
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents/query/workplace/getWorkPlaceDocumentCategories`,
    method: "GET",
    params: { workplace_id: id, limit: perPage, page: page },
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function getWorkPlaceDocuments(req: any) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents/query/workplace/getWorkPlaceDocuments`,
    method: "GET",
    headers: { "x-auth-token": localStorage.getItem("token") },
    params: {
      workplace_id: req.id,
      object_id: req.empid,
      object_type: "User",
      limit: req.perPage,
      page: req.page,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

async function createDocumentCategories(req: any) {
  return await axios({
    // Endpoint to send files
    url: `${BASE_API_URL}/platform/documents`,
    data: [
      {
        documentCategories: {
          crud: "C",
          data: [
            {
              workplace_id: req.id,
              category_name:req.category_name,
              category_notif_int_days: req.category_notif_int_days,
            },
          ],
        },
      },
    ],
    method: "POST",
    headers: { "x-auth-token": localStorage.getItem("token") },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export default workspaceServices;
