import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import workspaceServices from '../../services/workspaceServices';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CardHOC } from '../hocs/CardHOC';
import FormTitle from '../common/FormTitle';

function CreateWorkPlace(props:any) {

    const [values,setValues]=useState({
      org_name:'',
      org_domain:''
    })

    function handleChange(e:any) {
      setValues({
        ...values,
        [e.target.name]:e.target.value
      })
    }
    
    const handleSubmit = () => {
      
      workspaceServices.createWorkPlace({org_name:values.org_name,org_domain:values.org_domain}).then(resp=>{
        if(resp.status){
          setValues({
            org_name:'',
            org_domain:''
          })
        }
        props.openSnackBar({message:"Workplace created successfully"})
      }).catch(e=>{
        props.openSnackBar({message:"Something went wrong! please try again",severity:"error"})
        console.log(e);
        
      })
    }
    return (<>
  
        {/* <div> */}
        <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={6}>
  <CardHOC>
  <Box component="div">
            <FormTitle 
            title='Create New Workplace' 
            subtiile='Review and update your account information below.'
            />
            <div>
            <TextField
              required
              name="org_name"
              label="Org. Name"
              type="text"
              id="org_name"
               variant="outlined"
                size='small'
              onChange={handleChange}
              value={values.org_name}
            />
            </div>
            <div>
            <TextField
              margin="normal"
              required
              name="org_domain"
              label="Org. Domain"
              type="text"
              id="org_domain"
               variant="outlined"
                         size='small'
              onChange={handleChange}
              value={values.org_domain}

            />
            </div>
            <div>
            <Button
              onClick={handleSubmit}  
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              
            >
              Create
            </Button>
            </div>
           
            
            <Grid container>
              
             
            </Grid>
          </Box>
  </CardHOC>
      </Grid>
      </Grid>

        {/* </div> */}
          
    </>);
}

const mapStateToProps = (state: any) => ({
  workplace: state.workplace,
  
});
const mapDispatchToProps = (dispatch:any) => ({
  openSnackBar: (state:{message:string,duration:number|undefined,severity:string}) => dispatch({ type: 'OPEN_SNACKBAR',payload:{
    message:state.message,duration:state.duration,severity:state.severity
  } }),
});

export default connect(mapStateToProps,mapDispatchToProps)(CreateWorkPlace);
