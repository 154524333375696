import  React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
type Props = {
  action: () => void
  name: string
  variant?: any
  IconComp?: any
  isLink?: boolean
  link?: string
  isDisabled?:boolean|undefined
};
export const CommonButton=(props: Props)=>{
  const {
    action,
    name,
    variant = "contained",
    IconComp,
    isLink = false,
    link = "",
    isDisabled=false
  } = props;
  
  return isLink ? (
    <Button
      variant={variant}
          disabled={isDisabled}

      sx={{
        m: 0,
        padding:"3px 5px",
        fontSize: "14px",
        border: "1px solid #008419",
        color: "#008419",
        background: "none",
        textTransform:"capitalize"
      }}
    >
      
      {IconComp && <IconComp sx={{ color: "#008419", mr: 1 }} />}
      {
        isDisabled?name:<Link to={link}>{name}</Link>
      }
      
    </Button>
  ) : (
    <Button
    disabled={isDisabled}
      onClick={isDisabled?undefined:action}
      variant={variant}
      sx={{
        m: 0,
        p: 1,
        fontSize: "14px",
        border: "1px solid #008419",
        color: "#008419",
        background: "none",
                textTransform:"capitalize"

      }}
    >
      {IconComp && <IconComp sx={{ color: "#008419", mr: 1 }} />} {name}
    </Button>
  );
};

