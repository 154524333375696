import { createStyles,Theme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import React from "react";

const LayoutStyle = makeStyles()((theme:Theme) =>{
    return {
      displayFlex: {
        display: "flex"
      },
      width100:{
        width:"100%"
      },
      padding16px:{
        padding:"16px 0"
      }
      }
})


export default LayoutStyle;