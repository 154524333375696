import React, { useEffect, useState } from "react";
import workspaceServices from "../../services/workspaceServices";
import CommonDataTable from "../common/CommonDataTable";
import commonStyle from "../../styles/commonStyle";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import AddBoxIcon from "@mui/icons-material/AddBox";

import Actions from "./Actions";
import { Typography } from "@mui/material";
import CommonDialog from "../common/CommonDialog";
const WorkSpace = (props: any) => {
  const { classes: commonClasses, cx } = commonStyle();
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [deleteWorplaceID, setDeleteWorplaceID] = useState(0);
  const buttons = [
    {
      type: "link",
      label: "Create Workplace",
      link: `/${props.workplace}/createworkplace`,
      icon: AddBoxIcon,
    },
  ];
  const columns = [
    {
      name: "Workplace name",
      cell: (item: any) => <span>{item?.workplace_name}</span>,
      width: "15%",
    },
    {
      name: "User name",
      cell: (item: any) => <span>{item?.creator_user_name}</span>,
      width: "20%",
    },
    {
      name: "Org. name",
      cell: (item: any) => <span>{item?.org_name}</span>,
      width: "17%",
      wrap: true,
    },
    {
      name: "Email",
      cell: (item: any) => <span>{item?.creator_email_id}</span>,
      width: "25%",
    },
    {
      name: "Created date",
      cell: (item: any) => (
        <span>
          {format(new Date(item?.created_datetime), "yyyy-MM-dd hh:mm a")}
        </span>
      ),
      width: "15%",
    },
    {
      name: "Action",
      cell: (item: any) => (
        <Actions
          item={item}
          workplace={props.workplace}
          handleDelete={handleDeletePopup}
        />
      ),
      width: "8%",
    },
  ];
  useEffect(() => {
    setGridLoading(true);
    getWorkSpaces();
  }, []);
  function handleDeletePopup(id: number) {
    setDeleteWorplaceID(id);
    handlepopup();
  }
  function handlepopup() {
    setOpenPopUp(!openPopUp);
  }
  function getWorkSpaces() {
    let req = {
      perPage: perPage,
      page: page,
    };
    apiCall(req);
  }
  function apiCall(req: any) {
    workspaceServices
      .getWorkSpace(req)
      .then((resp) => {
        if (resp.status === 200) {
          setData(resp.data.data.getWorkPlaces.rows);
          setTotalRecords(resp.data.data.getWorkPlaces.totalCount);
        } else {
          setData([]);
          setTotalRecords(0);
        }
        setGridLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setGridLoading(false);
      });
  }
  function handleChangePage(pageNumber: number) {
    setPage(pageNumber);
    let req = {
      perPage: perPage,
      page: pageNumber,
    };
    apiCall(req);
  }
  function handleChangeRowsPerPage(rowCount: number) {
    setPage(1);
    setPerPage(rowCount);

    let req = {
      perPage: rowCount,
      page: 1,
    };
    apiCall(req);
  }
  function handleDelete() {
    workspaceServices
      .deleteWorkPlace(deleteWorplaceID)
      .then((resp) => {
        if (resp.data.statusCode === 201) {
          handlepopup();
          getWorkSpaces();

          props.openSnackBar({
            message: "Workplace deleted successfully",
            severity: "success",
          });
        } else {
          props.openSnackBar({
            message: "Something went wrong, please try again",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        props.openSnackBar({
          message: "Something went wrong, please try again",
          severity: "error",
        });
      });
  }
  return (
    <div className={cx(commonClasses.fullWidth)}>
      <Typography variant={"h6"}>Workplace</Typography>

      {!gridLoading && (
        <CommonDataTable
          columns={columns}
          data={data}
          buttons={buttons}
          title="Workplace"
          perPage={perPage}
          page={page}
          totalRecords={totalRecords}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          placeholder="Search Users by Name, Email"
        />
      )}

      {openPopUp && (
        <CommonDialog
          title="Delete workplace"
          showCancelBtn={true}
          showActionBtn={true}
          cancelButtonName="Cancel"
          actionButtonName="Delete"
          cancelHandler={handlepopup}
          actionHandler={handleDelete}
        >
          <Typography>
            Are you sure you want to delete workplace? You can't undo this
            action
          </Typography>
        </CommonDialog>
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  workplace: state.workplace,
});

const mapDispatchToProps = (dispatch: any) => ({
  openSnackBar: (state: {
    message: string;
    duration: number | undefined;
    severity: string;
  }) =>
    dispatch({
      type: "OPEN_SNACKBAR",
      payload: {
        message: state.message,
        duration: state.duration,
        severity: state.severity,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkSpace);
