import DashboardIcon from '@mui/icons-material/Dashboard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleIcon from '@mui/icons-material/People';
import ContactsIcon from '@mui/icons-material/Contacts';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
const data=[
    {
      "name": "Dashboard",
      "link": "",
      "Icon": DashboardIcon,
    },
    {
      "name": "WorkPlaces",
      "link": "/workplaces",
      "Icon": ApartmentIcon,
      isPlatformAdmin:true

    },
    {
      "name": "Users",
      "link": "/users",
      "Icon": PeopleIcon
    },
    {
      "name": "Employees",
      "link": "/employees",
      "Icon": ContactsIcon
    },
    {
      "name": "Document Categories",
      "link": "/documentcategories",
      "Icon": DocumentScannerIcon,
      isPlatformAdmin:true

    },
   
    
    // {
    //   "name": "Nested Pages",
    //   "Icon": DashboardIcon,
    //   "items": [
    //     {
    //       "name": "Level 2"
    //     },
    //     {
    //       "name": "Level 2",
    //       "items": [
    //         {
    //           "name": "Level 3"
    //         },
    //         {
    //           "name": "Level 3"
    //         }
    //       ]
    //     }
    //   ]
    // }
  ]

  export default data;
