import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearWithValueLabel(props: LinearProgressProps & { value: number,colorValue:string }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <Typography>{props.title}</Typography>
        <LinearProgress variant="determinate" {...props} color='inherit' sx={{height:"8px",borderRadius:"70px",color:`${props.colorValue}`,backgroundColor:`${props.colorValue}26`}} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LinearProgressWithLabel(props:{percentage:number,title:string,colorValue:string}) {

  return (
    <Box sx={{ width: '100%' }}>
      <LinearWithValueLabel title={props.title} value={props.percentage} colorValue={props.colorValue} />
    </Box>
  );
}
