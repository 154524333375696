import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React,{useEffect} from 'react';
import { connect } from 'react-redux';
function CommonSnackBar(props:any) {
    const [open, setOpen] = React.useState(false);
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    props.closeSnackBar()
  };
  useEffect(()=>{
    setOpen(props.openSnackbar)
    
  },[props.openSnackbar])

    return ( 
        <Snackbar open={open}
        anchorOrigin={{vertical:"top",horizontal:"center"}}
         autoHideDuration={props.duration} 
        onClose={handleClose}>
        <MuiAlert onClose={handleClose} severity={props.severity?props.severity:"success"} sx={{ width: '100%' }}>
          {props.message}
        </MuiAlert>
      </Snackbar>
       );
}

const mapStateToProps = (state: any) => ({
    openSnackbar: state.snackbar.openSnackbar,
    message:state.snackbar.message,
    duration:state.snackbar.duration,
    severity:state.snackbar.severity
});
const mapDispatchToProps = (dispatch:any) => ({
    closeSnackBar: () => dispatch({ type: 'CLOSE_SNACKBAR',payload:{} }),
  });

export default connect(mapStateToProps,mapDispatchToProps)(CommonSnackBar);