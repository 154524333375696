import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import React, { ReactEventHandler, useEffect, useState } from "react";
import { CardHOC } from "../hocs/CardHOC";
import FormTitle from "../common/FormTitle";
import CommonDataTable from "../common/CommonDataTable";
import { connect } from "react-redux";
import workspaceServices from "../../services/workspaceServices";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const DocumentList = (props: any) => {
  const [values, setValues] = useState({
    category_name: "",
    category_notif_int_days: 0,
  });
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const [gridLoading, setGridLoading] = useState(false);
  useEffect(() => {
    fetchDocCategories();
  }, []);
  const columns = [
    {
      name: "Category Name",
      cell: (item: any) => <span>{item.value}</span>,
      width: "80%",
    },

    {
      name: "Action",
      cell: (item: any) => (
        <Box>
          <IconButton
            aria-label="Edit"
            size="small"
            onClick={() => handleEdit(item)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="Delete"
            size="small"
            sx={{ marginLeft: "5px" }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      ),
      width: "20%",
    },
  ];

  function fetchDocCategories() {
    let req = {
      id: props.workplaceid,
      perPage: perPage,
      page: page,
    };
    apiCall(req);
  }

  function apiCall(req: any) {
    workspaceServices.getWorkPlaceDocumentCategories(req).then((resp) => {
      if (resp.data.statusCode === 200) {
        setData(resp.data.data.getWorkPlaceDocumentCategories.rows);
        setTotalRecords(resp.data.data.getWorkPlaceDocumentCategories.totalCount)
      }else{
         setData([]);
        setTotalRecords(0)
      }
    });
  }
  function handleEdit(item: any) {
    console.log(item);
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  }

  function handleSubmit() {
    let req = {
      id: props.workplaceid,
      category_name:values.category_name,
      category_notif_int_days:values.category_notif_int_days
    };
    
    workspaceServices
      .createDocumentCategories(req)
      .then((resp) => {
        if (resp.data.statusCode === 201) {
          props.openSnackBar({ message: "Document info saved successfully" });
          setValues({
            category_name: "",
            category_notif_int_days: 0,
          });
          setPage(1);
          let req = {
            id: props.workplaceid,
            perPage: perPage,
            page: 1,
          };
          apiCall(req);
        } else {
          props.openSnackBar({
            message: "Something went wrong! please try again",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        props.openSnackBar({
          message: "Something went wrong! please try again",
          severity: "error",
        });
      });
  }

  function handleChangePage(pageNumber: number) {
    let req = {
      perPage: pageNumber,
      page: page,
      id: props.workplaceid,
    };
    apiCall(req);
  }
  function handleChangeRowsPerPage(rowCount: number) {
    setPage(1);
    setPerPage(rowCount);

    let req = {
      perPage: rowCount,
      page: 1,
      id: props.workplaceid,
    };
    apiCall(req);
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} xl={9}>
          <CardHOC>
            <FormTitle title="Create/Update Document Category" />
            <Box component="div">
              <div>
                <TextField
                  sx={{ width: 300 }}
                  margin="normal"
                  required
                  name="category_name"
                  label="Document Category"
                  type="text"
                  id="category_name"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.category_name}
                />
              </div>
              <div>
                <TextField
                  sx={{ width: 300 }}
                  margin="normal"
                  required
                  name="category_notif_int_days"
                  label="Category notification in days"
                  type="number"
                  id="category_notif_int_days"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.category_notif_int_days}
                />
              </div>
              <Box sx={{ width: 300, textAlign: "right" }}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={
                    values.category_name.trim() === "" ||
                    values.category_notif_int_days < 0 ||
                    values.category_notif_int_days.toString() === ""
                  }
                >
                  Save
                </Button>
              </Box>

              <Grid container></Grid>
            </Box>

            <Box sx={{ width: "100%" }}>
              <CommonDataTable
                columns={columns}
                data={data}
                title="Workplace Employee List"
                perPage={perPage}
                page={page}
                totalRecords={totalRecords}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                placeholder="Search Employee by Name, Email"
                showSearch={false}
              />
            </Box>
          </CardHOC>
        </Grid>
      </Grid>
    </Box>
  );
};
const mapStateToProps = (state: any) => ({
  workplace: state.workplace,
  workplaceid: state.workplaceid,
});
const mapDispatchToProps = (dispatch: any) => ({
  openSnackBar: (state: {
    message: string;
    duration: number | undefined;
    severity: string;
  }) =>
    dispatch({
      type: "OPEN_SNACKBAR",
      payload: {
        message: state.message,
        duration: state.duration,
        severity: state.severity,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentList);
