import React, { useState, useEffect } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import SignIn from "../components/SignIn";
import AppMenuItem from "../components/Menu/AppMenuItem";
import LayoutStyle from "./LayoutStyle";
import menuStyle from "../components/Menu/menuStyle";
import HeaderContainer from "../components/Header/HeaderContainer";
import { Box, Container, Divider, Grid } from "@mui/material";
import Registration from "./Registration";
import Verify from "../components/Registration/Verify";
import { connect } from "react-redux";
import WorkSpace from "../components/workplace/WorkSpace";
import PrivateRoute from "../PrivateRoute";
import Cookies from "universal-cookie";
import HeaderLogo from "../components/Header/HeaderLogo";
import HeaderUserInfo from "../components/Header/HeaderUserInfo";
import withRouter from "./withRouter";
import CreateWorkPlace from "../components/workplace/CreateWorkPlace";
import CreateUser from "../components/Users/CreateUser";
import WorkPlaceUsers from "../components/Users/WorkPlaceUsers";
import CreateEmployee from "../components/Employee/CreateEmployee";
import WorkPlaceEmployees from "../components/Employee/WorkPlaceEmployees";
import DocumentUpload from "../components/Employee/DocumentUpload";
import Dashboard from "../components/Dashboard/Dashboard";

const Layout = (props: any) => {
  // const [isAuthenticatedUser, setIsAuthenticated] = useState(false)
  const { classes, cx } = LayoutStyle();
  const { classes: menuClasses } = menuStyle();
  const cookies = new Cookies();

  const navigate = useNavigate();
  useEffect(() => {
    if (!cookies.get("isAuthenticatedUser")) {
      props.setIsAuthenticated(false, "");
      navigate("/signin");
    } else if (!props.location.pathname.includes(props.workplace)) {
      navigate(`/${props.workplace}`);
    }
  }, []);
  return (
    <>
      {
        <React.Fragment>
          {/* {props.isAuthenticatedUser && <HeaderContainer />} */}

          <Grid container sx={{ padding: "0", minHeight: "100vh" }}>
            {props.isAuthenticatedUser && (
              <Grid item xs={12} md={2} xl={2}>
                <Box className={cx(menuClasses.menuBg)}>
                  <HeaderLogo />
                  <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.3)" }} />

                  <AppMenuItem />
                </Box>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={props.isAuthenticatedUser ? 10 : 12}
              xl={props.isAuthenticatedUser ? 10 : 12}
              sx={{ paddingBottom: "32px" }}
            >
              {props.isAuthenticatedUser && <HeaderUserInfo />}
              <Grid container sx={{ padding: "0 16px", marginTop: "16px" }}>
                <Grid item xs={12}>
                 <Outlet />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <div className={cx('menu-contect-section', classes.displayFlex)}>
                             {props.isAuthenticatedUser && <Grid className={cx(menuClasses.menuBg)}>
                                <Grid container>

                                    <AppMenuItem />
                                </Grid>

                            </Grid>}
                            <Grid className={cx(classes.width100,classes.padding16px)}>
                                <Grid container>
                                <Grid item xs={12}>
                                {props.children}

                                </Grid>

                                </Grid>
                            </Grid>

                        </div> */}
        </React.Fragment>
      }
    </>
  );
};
const mapStateToProps = (state: any) => ({
  isAuthenticatedUser: state.isAuthenticatedUser,
  workplace: state.workplace,
});
const mapDispatchToProps = (dispatch: any) => ({
  setIsAuthenticated: (
    isAuthenticatedUser: boolean,
    workplace: string | undefined
  ) =>
    dispatch({
      type: "SET_AUTHENTICATED",
      payload: {
        isAuthenticatedUser: isAuthenticatedUser,
        workplace: workplace,
      },
    }),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
