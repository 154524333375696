import React, { useEffect, useState } from "react";
import workspaceServices from "../../services/workspaceServices";
import CommonDataTable from "../common/CommonDataTable";
import commonStyle from "../../styles/commonStyle";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  IconButton,
  Tooltip,
  TextField,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import Autocomplete from "@mui/material/Autocomplete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UserAction from "./UserAction";
import CommonDialog from "../common/CommonDialog";

const WorkPlaceUsers = (props: any) => {
  const { classes: commonClasses, cx } = commonStyle();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [workplacedata, setWorkplaceData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [openPopUp,setOpenPopUp]=useState(false)
  const [gridLoading, setGridLoading] = useState(false);
  const [workplaceoptions, setworkplaceoptions] = useState([]);
  const [selectedWorkplace, setselectedWorkplace] = useState<{ id?: number }>();
  const [deleteUserID,setDeleteUserID]=useState(0)
  const navigate = useNavigate();
  
  function handleEditUser(id: number) {
    navigate(`/${props.workplace}/edituser/${props.isPlatformAdmin?selectedWorkplace?.id:props.workplaceid}/${id}`, { replace: true });
  }

  
  const buttons = [
    {
      type: "link",
      label: "Create User",
      link: `/${props.workplace}/createuser/${props.isPlatformAdmin?selectedWorkplace?.id:props.workplaceid}`,
      icon: AddBoxIcon,
      isDisabled:props.role==="Normal"
    },
  ];

  const columns = [
    {
      name: "User name",
      cell: (item: any) => <span>{item?.user_name}</span>,
      width: "25%",
    },
    {
      name: "Email",
      cell: (item: any) => <span>{item?.email_id}</span>,
      width: "25%",
      wrap: true,
    },
    {
      name: "Role",
      cell: (item: any) => <span>{item?.workplace_role}</span>,
      width: "20%",
      wrap: true,
    },
    {
      name: "Created date",
      cell: (item: any) => (
        <span>
          {format(new Date(item?.created_datetime), "yyyy-MM-dd hh:mm a")}
        </span>
      ),
      width: "20%",
    },
    {
      name: "Action",
      cell: (item: any) => (
        <UserAction handleEditUser={handleEditUser} deleteWorkPlaceUser={deleteWorkPlaceUser} item={item} />
      ),
      width: "10%",
    },
  ];
  useEffect(() => {
    setGridLoading(true);
    if(props.isPlatformAdmin){
    getWorkPlaces();

    }else{
     getWorkPlaceUsers(props.workplaceid)

    }
  }, []);

  function deleteWorkPlaceUser(id: number) {
    // console.log("id", id);
setDeleteUserID(id);
setOpenPopUp(true);
    
  }
function handleDeleteUser() {
  workspaceServices.deleteWorkPlaceUser({id:deleteUserID,workplace_id:props.isPlatformAdmin?selectedWorkplace?.id:props.workplaceid}).then((resp) => {
        if (resp.data.statusCode === 201) {
          props.openSnackBar({ message: "User deleted successfully" });
          setOpenPopUp(false)
 if(props.isPlatformAdmin){
    getWorkPlaces();

    }else{
     getWorkPlaceUsers(props.workplaceid)

    }
        } else {
          props.openSnackBar({
            message: "Something went wrong! please try again",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        props.openSnackBar({
          message: "Something went wrong! please try again",
          severity: "error",
        });
      });
}
  function handleChange(params: any) {
    if (params !== null) {
      setselectedWorkplace(params);
      getWorkPlaceUsers(params.id);
    } else {
      let wp: any = workplaceoptions[0];
      setselectedWorkplace(workplaceoptions[0]);

      getWorkPlaceUsers(wp["id"]);
    }
  }
  function getWorkPlaces() {
    let req = {
      perPage: 100000,
      page: 1,
    };
    workspaceServices
      .getWorkSpace(req)
      .then((resp) => {
        if (resp.status === 200) {
          setworkplaceoptions(resp.data.data.getWorkPlaces.rows);

          setselectedWorkplace(resp.data.data.getWorkPlaces.rows[0]);
          getWorkPlaceUsers(resp.data.data.getWorkPlaces.rows[0].id);
        } else {
          setworkplaceoptions([]);
        }
        setGridLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setGridLoading(false);
      });
  }
  function apiCall(req: any) {
    setGridLoading(true);

    workspaceServices
      .getWorkPlaceUsers(req)
      .then((resp) => {
        if (resp.status === 200) {
          setData(resp.data.data.getWorkPlaceUsers.rows);
          setTotalRecords(resp.data.data.getWorkPlaceUsers.totalCount);
        } else {
          setData([]);
          setTotalRecords(0);
        }
        setGridLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setGridLoading(false);
      });
  }
  function getWorkPlaceUsers(id: number) {
    let req = {
      perPage: perPage,
      page: page,
      id: id,
    };
    apiCall(req);
  }
  function handleChangePage(pageNumber: number) {
    setPage(pageNumber);
    let req = {
      perPage: perPage,
      page: pageNumber,
      id: selectedWorkplace?.id,
    };
    apiCall(req);
  }
   function handleChangeRowsPerPage(rowCount:number) {
        setPage(1)
        setPerPage(rowCount)
        
         let req = {
            perPage: rowCount,
            page: 1,
           id: selectedWorkplace?.id,
        }
       apiCall(req);
    }
  return (
    <div className={cx(commonClasses.fullWidth)}>
      <div>
        {props.isPlatformAdmin && (
          <Autocomplete
            disablePortal
            value={selectedWorkplace !== undefined ? selectedWorkplace : {}}
            id="combo-box-demo"
            options={workplaceoptions}
            getOptionLabel={(option: any) => option.workplace_name}
            onChange={(event: any, newValue: any) => {
              handleChange(newValue);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select workplace"
                variant="outlined"
                size='small'
              />
            )}
          />
        )}
      </div>
      {!gridLoading && (
        <CommonDataTable
          columns={columns}
          data={data}
          title="Workplace Users List"
          buttons={buttons}
          perPage={perPage}
          page={page}
          totalRecords={totalRecords}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          placeholder="Search Users by Name, Email"
        />
      )}

{
  openPopUp && 
  <CommonDialog 
      title="Delete User"
      showCancelBtn={true}
      cancelButtonName={"No"}
      showActionBtn={true}
      actionButtonName="Yes"
      cancelHandler={()=>setOpenPopUp(false)}
      actionHandler={handleDeleteUser}
      >
Are you sure you want to delete this user?
        </CommonDialog>
}
      
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  workplace: state.workplace,
    isPlatformAdmin: state.isPlatformAdmin,
  role:state.role,
  workplaceid:state.workplaceid
});
const mapDispatchToProps = (dispatch: any) => ({
  openSnackBar: (state: {
    message: string;
    duration: number | undefined;
    severity: string;
  }) =>
    dispatch({
      type: "OPEN_SNACKBAR",
      payload: {
        message: state.message,
        duration: state.duration,
        severity: state.severity,
      },
    }),
});
export default connect(mapStateToProps,mapDispatchToProps)(WorkPlaceUsers);
