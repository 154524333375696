import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const CommonDialog = (props:any) => {

  const handleClose = () => {
    props.cancelHandler();
  };
  function handleAction() {
    props.actionHandler();
  }
  return (
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
        {props.children}
        </DialogContent>
        <DialogActions>
          {
            props.showCancelBtn &&  <Button onClick={handleClose} >
            {props.cancelButtonName}
          </Button>
          }
          {
            props.showActionBtn &&  <Button onClick={handleAction}                         variant="contained"
 autoFocus>
            {props.actionButtonName}
          </Button>
          }
         
        </DialogActions>
      </Dialog>
  );
};

export default CommonDialog;
