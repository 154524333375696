import React, { useEffect } from 'react';
import './App.css';
import Layout from './containers/Layout';
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from './theme';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import './styles/commonStyle.scss'
// import SignIn from './components/Registration/RegistrationForm';
import Verify from './components/Registration/Verify';
import Registration from './containers/Registration';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import WorkSpace from './components/workplace/WorkSpace';
import SignIn from './components/SignIn';
import CreateWorkPlace from './components/workplace/CreateWorkPlace';
import CommonSnackBar from './components/common/CommonSnackBar';
import CreateUser from './components/Users/CreateUser';
import WorkPlaceUsers from './components/Users/WorkPlaceUsers';
import CreateEmployee from './components/Employee/CreateEmployee';
import WorkPlaceEmployees from './components/Employee/WorkPlaceEmployees';
import Dashboard from './components/Dashboard/Dashboard';
import DocumentUpload from './components/Employee/DocumentUpload';
import withRouter from './containers/withRouter';
import ViewDocuments from './components/Employee/ViewDocuments';
import Profile from './components/Users/Profile';
import DocumentList from './components/Documents/DocumentList';

function App(props: any) {
	const location = useLocation()
    const navigate = useNavigate();

useEffect(()=>{
  if(props.isAuthenticatedUser){
if(window.location.pathname==="/"){
        navigate(`/${props.workplace}`)

  }
  }else{
    navigate('/signin')
  }
  
  
},[window.location.pathname])
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<div className="">
				<CommonSnackBar />
					<Routes>
					<Route path='/signin' element={<SignIn />} />

					<Route path={`${props.workplace}`} element={<PrivateRoute>
						<Layout />
					</PrivateRoute>
				} >
                     <Route
                      path={``}
                      element={
                        <PrivateRoute>
                          <Dashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={`workplaces`}
                      element={
                        <PrivateRoute>
                          <WorkSpace />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={`createworkplace`}
                      element={
                        <PrivateRoute>
                          <CreateWorkPlace />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={`createuser/:id`}
                      element={
                        <PrivateRoute>
                          <CreateUser />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={`edituser/:id/:userid`}
                      element={
                        <PrivateRoute>
                          <CreateUser isEdit={true} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={`users`}
                      element={
                        <PrivateRoute>
                          <WorkPlaceUsers />
                        </PrivateRoute>
                      }
                    />
                     <Route
                      path={`profile/:id/:userid`}
                      element={
                        <PrivateRoute>
                          <Profile />
                        </PrivateRoute>
                      }
                    />
                    
                    <Route
                      path={`createemployee/:id`}
                      element={
                        <PrivateRoute>
                          <CreateEmployee isEdit={false} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={`editemployee/:id/:empid`}
                      element={
                        <PrivateRoute>
                          <CreateEmployee isEdit={true} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={`employees`}
                      element={
                        <PrivateRoute>
                          <WorkPlaceEmployees />
                        </PrivateRoute>
                      }
                    />
                    
                    <Route
                      path={`documentcategories`}
                      element={
                        <PrivateRoute>
                          <DocumentList />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={`documentsupload/:empid`}
                      element={
                        <PrivateRoute>
                          <DocumentUpload />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={`viewdocuments/:id/:empid`}
                      element={
                        <PrivateRoute>
                          <ViewDocuments />
                        </PrivateRoute>
                      }
                    />

                    {/* </Route> */}
                    {/* <Route path={`/${props.workplace}` element={}} */}
                 
					</Route> 

						   <Route path="/verification" element={<Verify />} />
                    <Route path="/register" element={<Registration />} />
						{/* <Route path="/" element={<Navigate replace to={props.workplace ? `/${props.workplace}/dashboard` : "/signin"} />} /> */}

					</Routes>

			</div>
		</ThemeProvider>
	);
}

const mapStateToProps = (state: any) => ({
	isAuthenticatedUser: state.isAuthenticatedUser,
	workplace: state.workplace
});
const mapDispatchToProps = (dispatch: any) => ({
	setIsAuthenticated: (isAuthenticatedUser: boolean, workplace: string | undefined) => dispatch({ type: 'SET_AUTHENTICATED', payload: { isAuthenticatedUser: isAuthenticatedUser, workplace: workplace } }),
});
export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
