import React, { FormEvent, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import commonStyle from "../../styles/commonStyle";
import theme from "../../theme";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import { CommonButton } from "./CommonButton";
import SearchIcon from "@mui/icons-material/Search";

const CommonDataTable = (props: any) => {
  const { classes: commonClasses, cx } = commonStyle();
  const [tableProps, setTableProps] = useState({});
  const [data, setData] = useState([]);
  const customStyles = {
    rows: {
      style: {
        minHeight: "40px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        background: "#F4F4F4",
        color: "#000000",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  function handleChangePage(event: any, newPage: number) {
    props.handleChangePage(newPage + 1);
  }
  function handleChangeRowsPerPage(params: any) {
    props.handleChangeRowsPerPage(params.target.value);
  }
  return (
    <div
      className={cx(
        commonClasses.displayFlex,
        commonClasses.flexDirectionColumn,
        commonClasses.fullWidth,
        commonClasses.paddingTop
      )}
    >
      <Grid
        container
        sx={{ padding: "10px", alignItems: "center", background: "#ffffff" }}
      >
        <Grid item xs={8}>
          {
            props.showSearch &&   <Box
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
              background: "#F4F4F4",
              borderRadius: "4px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={props.placeholder ? props.placeholder : "Search...."}
              inputProps={{
                "aria-label": props.placeholder
                  ? props.placeholder
                  : "Search....",
              }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="primary"
              sx={{ p: "0px 5px" }}
              aria-label="directions"
            >
              <SearchIcon />
            </IconButton>
          </Box>
          }
        
        </Grid>
        {/* <Grid item xs={4}></Grid> */}
        <Grid item xs={4}>
          <div
            className={cx(
              commonClasses.displayFlex,
              commonClasses.justifyContentEnd
            )}
          >
            {props.buttons && props.buttons?.length > 0 && (
              <>
                {props.buttons.map((button: any, index: number) => {
                  return (
                    <CommonButton
                      isLink={button.type === "link"}
                      link={button.link}
                      name={button.label}
                      action={button.action}
                      variant="outlined"
                      IconComp={button.icon}
                      isDisabled={button.isDisabled}
                    />
                  );
                })}
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <div className={cx(commonClasses.fullWidth)}>
        <DataTable
          columns={props.columns}
          data={data}
          customStyles={customStyles}
        />
      </div>
      <div>
        <TablePagination
          component="div"
          count={props.totalRecords}
          page={props.page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={props.perPage}
          showFirstButton={true}
          showLastButton={true}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </div>
    </div>
  );
};

export default CommonDataTable;
