import axios from "axios";
import {
    BASE_API_URL
} from "./Env";

const loginServices = {
    register,
    verify,
    login
}

async function register(email: string, password: string) {
    return await axios({

        // Endpoint to send files
        url: `${BASE_API_URL}/platform/auth/register`,
        method: "POST",
        data: {
            "email_id": email,
            "password": password
        },
    }).then((res) => {
        return res
    }).catch((err) => {
        return err
    });
}

async function verify(token:string) {
    return await axios({

        // Endpoint to send files
        url: `${BASE_API_URL}/auth/verify?token=${token}`,
        method: "GET",
    }).then((res) => {
        return res
    }).catch((err) => {
        return err
    });
}

async function login(email: string, password: string) {
    return await axios({

        // Endpoint to send files
        url: `${BASE_API_URL}/platform/auth/login`,
        method: "POST",
        data: {
            "email_id": email,
            "password": password
        },
    }).then((res) => {
        return res
    }).catch((err) => {
        return err
    });
}
export default loginServices;