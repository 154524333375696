import React, { useState } from 'react';
import MenuItemRoot from './MenuItemRoot';
import List from '@mui/material/List';
import menuData from './MenuData';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import menuStyle from './menuStyle';
import { connect } from 'react-redux';
import { log } from 'console';

const drawerWidth = 240;
type menuType={
    name:string,
    Icon:String|undefined,
    items:Object[]|undefined
}


const AppMenuItem = (props:any) => {
  const clasess=menuStyle();
  function getMenuItem(item:any,index:number) {
    if(item.isPlatformAdmin){
        if(item.isPlatformAdmin===props.isPlatformAdmin){
            return <MenuItemRoot {...item} key={index} />
        }else{
            return '';
        }
    }
    return <MenuItemRoot {...item} key={index} />
}
    return (
       
            
                <List component="nav" disablePadding>
                    {
                        menuData.map((item,index)=>{
                            return  getMenuItem(item,index)
                        })
                    }

        </List>
    );
};

const mapStateToProps = (state: any) => ({
  isPlatformAdmin: state.isPlatformAdmin,
});
export default connect(mapStateToProps)(AppMenuItem);
