import * as React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
export const options = {
  plugins: {
    legend: {
      position: "bottom" as const,
      display: true, // This hides the legend
      // position:"bottom"
    },
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: number, index: number) {
            return "$" + value;
          },
        },
      },
    },
    datalabels: {
      display: true,
      formatter: (value: any, ctx: any) => {
        return value !== 0 ? Math.round(value).toFixed(2) + "%" : "";
      },
      color: "#fff",
    },
  },
};

export const PieCharts = (props: any) => {
  const data = {
    labels: ["Passport", "Medical Insurance", "ID Card"],
    datasets: [
      {
        label: "",
        data: [
          props.action("Passport"),
          props.action("Medical Insurance"),
          props.action("ID Card"),
        ],
        backgroundColor: ["#FFB200", "#4339F2", "#02A0FC"],
        borderWidth: 0,
      },
    ],
  };
  return (
    <div>
      <Pie data={data} options={options} />
    </div>
  );
};
