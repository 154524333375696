import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import workspaceServices from "../../services/workspaceServices";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import withRouter from "../../containers/withRouter";
import commonStyle from "../../styles/commonStyle";
import countryList from "../../utils/Contries.json";
import { CardHOC } from "../hocs/CardHOC";
import FormTitle from "../common/FormTitle";

function CreateEmployee(props: any) {
        const navigate = useNavigate();

  const { classes: commonClasses, cx } = commonStyle();
  const salutation = [
    {
      name: "Mr",
      value: "Mr",
    },
    {
      name: "Mrs",
      value: "Mrs",
    },
    {
      name: "Ms",
      value: "Ms",
    },
  ];
  const [workplace_id, setWorkPleaceID] = useState(0);
  const [selectedsalutation, setselectedsalutation] = useState(salutation[0]);
const [selectedCountry,setSelectedCountry]=useState(countryList[0])
  const [empid, setEmpId] = useState(0);
  const [empData,setEmpData]=useState()
  const [values, setValues] = useState({
    employee_gender: "",
    employee_salutation: "",
    employee_first_name: "",
    employee_middle_name: "",
    employee_last_name: "",
    employee_email: "",
    employee_alt_email: "",
    employee_mobile_number: "",
    employee_alt_mobile_number: "",
    employee_country: "",
    employee_country_code: "",
    employee_designation: "",
    employee_permanent_address: "",
    employee_current_primary_address: "",
    employee_current_alt_address: "",
  });

  useEffect(() => {
    if (props?.params?.id !== undefined) {
      setWorkPleaceID(props?.params?.id);
    }
    if (props.isEdit) {
      setEmpId(props?.params?.empid);
      getEmployeeDetails(props?.params?.id, props?.params?.empid);
    }
  }, []);
  function getEmployeeDetails(wid: number, eid: number) {
    workspaceServices.getWorkPlaceEmployeesByID({id:wid,empid:eid}).then(resp=>{
              if (resp.status === 200) {
                let data=resp.data.data.getWorkPlaceEmployees.rows[0];
                      setEmpData(data)
                      let tempsal=salutation.find(item=>item.value===data.employee_salutation)
                      if(tempsal!==undefined){
                      setselectedsalutation(tempsal)

                      }
                      let tempCountry=countryList.find(item=>item.name===data.employee_country)
                      if(tempCountry!==undefined){
                        setSelectedCountry(tempCountry)
                      }
                      setValues({
    employee_gender: data.employee_gender,
    employee_salutation: data.employee_salutation,
    employee_first_name: data.employee_first_name,
    employee_middle_name: data.employee_middle_name,
    employee_last_name: data.employee_last_name,
    employee_email: data.employee_email,
    employee_alt_email: data.employee_alt_email,
    employee_mobile_number: data.employee_mobile_number,
    employee_alt_mobile_number: data.employee_alt_mobile_number,
    employee_country: data.employee_country,
    employee_country_code: data.employee_country_code,
    employee_designation: data.employee_designation,
    employee_permanent_address: data.employee_permanent_address,
    employee_current_primary_address: data.employee_current_primary_address,
    employee_current_alt_address: data.employee_current_alt_address,
  })

              }else{
      setEmpData(undefined)

              }
    }).catch(e=>{

    })
  }
  function handleChange(e: any) {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  }
  function handleRoleChange(e: any) {
    setselectedsalutation(e);
    setValues({...values,employee_salutation:e.value})
  }
  function handleCountryChange(e: any) {
    if (e !== null) {
      setValues({
        ...values,
        employee_country: e.name,
        employee_country_code: e.code,
      });
    } else {
      setValues({
        ...values,
        employee_country: "",
        employee_country_code: "",
      });
    }
  }
  const handleSubmit = () => {
    let req = {
      workplace_id: workplace_id,
      employee_gender: values.employee_gender,
      employee_salutation: values.employee_salutation,
      employee_first_name: values.employee_first_name,
      employee_middle_name: values.employee_middle_name,
      employee_last_name: values.employee_last_name,
      employee_email: values.employee_email,
      employee_alt_email: values.employee_alt_email,
      employee_mobile_number: values.employee_mobile_number,
      employee_alt_mobile_number: values.employee_alt_mobile_number,
      employee_country: values.employee_country,
      employee_country_code: values.employee_country_code,
      employee_designation: values.employee_designation,
      employee_permanent_address: values.employee_permanent_address,
      employee_current_primary_address: values.employee_current_primary_address,
      employee_current_alt_address: values.employee_current_alt_address,
    };
    workspaceServices
      .createWorkPlaceEmployee(req)
      .then((resp) => {
        if (resp.data.statusCode === 201) {
          setValues({
            employee_gender: "",
            employee_salutation: "",
            employee_first_name: "",
            employee_middle_name: "",
            employee_last_name: "",
            employee_email: "",
            employee_alt_email: "",
            employee_mobile_number: "",
            employee_alt_mobile_number: "",
            employee_country: "",
            employee_country_code: "",
            employee_designation: "",
            employee_permanent_address: "",
            employee_current_primary_address: "",
            employee_current_alt_address: "",
          });
          setselectedsalutation(salutation[0]);
          props.openSnackBar({ message: "Employee created successfully" });
        } else {
          props.openSnackBar({
            message: "Something went wrong! please try again",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        props.openSnackBar({
          message: "Something went wrong! please try again",
          severity: "error",
        });
      });
  };

  const handleUpdate = () => {
    let req = {
      workplace_id: workplace_id,
      employee_gender: values.employee_gender,
      employee_salutation: values.employee_salutation,
      employee_first_name: values.employee_first_name,
      employee_middle_name: values.employee_middle_name,
      employee_last_name: values.employee_last_name,
      employee_email: values.employee_email,
      employee_alt_email: values.employee_alt_email,
      employee_mobile_number: values.employee_mobile_number,
      employee_alt_mobile_number: values.employee_alt_mobile_number,
      employee_country: values.employee_country,
      employee_country_code: values.employee_country_code,
      employee_designation: values.employee_designation,
      employee_permanent_address: values.employee_permanent_address,
      employee_current_primary_address: values.employee_current_primary_address,
      employee_current_alt_address: values.employee_current_alt_address,
      id:empid
    };
    workspaceServices
      .updateWorkPlaceEmployee(req)
      .then((resp) => {
        if (resp.data.statusCode === 201) {
          setValues({
            employee_gender: "",
            employee_salutation: "",
            employee_first_name: "",
            employee_middle_name: "",
            employee_last_name: "",
            employee_email: "",
            employee_alt_email: "",
            employee_mobile_number: "",
            employee_alt_mobile_number: "",
            employee_country: "",
            employee_country_code: "",
            employee_designation: "",
            employee_permanent_address: "",
            employee_current_primary_address: "",
            employee_current_alt_address: "",
          });
          setselectedsalutation(salutation[0]);
          props.openSnackBar({ message: "Employee updated successfully" });
          navigate(`/${props.workplace}/employees`, { replace: true });

        } else {
          props.openSnackBar({
            message: "Something went wrong! please try again",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        props.openSnackBar({
          message: "Something went wrong! please try again",
          severity: "error",
        });
      });
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} xl={9}>
          <CardHOC>
            <FormTitle
              title={`${props.isEdit ? "Edit" : "Create New"} Employee`}
            />
            <Box component="div">
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <Autocomplete
                  disablePortal
                  value={selectedsalutation}
                  size="small"
                  id="combo-box-demo"
                  options={salutation}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(event: any, newValue: any) => {
                    handleRoleChange(newValue);
                  }}
                  sx={{
                    width: 70,
                    mb: "8px",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                  renderInput={(params) => <TextField {...params} label="" />}
                />
                <TextField
                  sx={{ width: 200, mr: 2, ml: 2 }}
                  margin="normal"
                  required
                  name="employee_first_name"
                  label="First Name"
                  type="text"
                  id="employee_first_name"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.employee_first_name}
                />
                <TextField
                  sx={{ width: 200, mr: 2 }}
                  margin="normal"
                  name="employee_middle_name"
                  label="Middle Name"
                  type="text"
                  id="employee_middle_name"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.employee_middle_name}
                />
                <TextField
                  sx={{ width: 200 }}
                  margin="normal"
                  required
                  name="employee_last_name"
                  label="Last Name"
                  type="text"
                  id="employee_last_name"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.employee_last_name}
                />
              </Box>
              <div>
                <FormControl sx={{ mt: 2 }}>
                  <FormLabel id="demo-employee_gender-label">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-employee_gender-label"
                    name="employee_gender"
                    value={values.employee_gender}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="Male"
                      control={<Radio />}
                      label="Male"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <TextField
                  sx={{ width: 400 }}
                  margin="normal"
                  required
                  name="employee_email"
                  label="Email"
                  type="text"
                  id="employee_email"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.employee_email}
                />
              </div>
              <div>
                <TextField
                  sx={{ width: 400 }}
                  margin="normal"
                  required
                  name="employee_alt_email"
                  label="Alternate Email"
                  type="text"
                  id="employee_alt_email"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.employee_alt_email}
                />
              </div>
              <div>
                <TextField
                  sx={{ width: 400 }}
                  margin="normal"
                  required
                  name="employee_mobile_number"
                  label="Mobile Number"
                  type="text"
                  id="employee_mobile_number"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.employee_mobile_number}
                />
              </div>
              <div>
                <TextField
                  sx={{ width: 400 }}
                  margin="normal"
                  required
                  name="employee_alt_mobile_number"
                  label="Alternate Mobile"
                  type="text"
                  id="employee_alt_mobile_number"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.employee_alt_mobile_number}
                />
              </div>
              <div>
                <Autocomplete
                  disablePortal
                  value={selectedCountry}
                  size="small"
                  id="combo-box-demo"
                  options={countryList}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(event: any, newValue: any) => {
                    handleCountryChange(newValue);
                  }}
                  sx={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Country"
                      variant="outlined"
                      margin="normal"
                    />
                  )}
                />
              </div>
              <div>
                <TextField
                  sx={{ width: 400 }}
                  margin="normal"
                  required
                  name="employee_designation"
                  label="Designation"
                  type="text"
                  id="employee_designation"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.employee_designation}
                />
              </div>
              <div>
                <TextField
                  sx={{ width: 400 }}
                  margin="normal"
                  required
                  multiline
                  rows={4}
                  name="employee_permanent_address"
                  label="Permanent Address"
                  type="text"
                  id="employee_permanent_address"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.employee_permanent_address}
                />
              </div>
              <div>
                <TextField
                  sx={{ width: 400 }}
                  margin="normal"
                  required
                  multiline
                  rows={4}
                  name="employee_current_primary_address"
                  label="Current Primary Address"
                  type="text"
                  id="employee_current_primary_address"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.employee_current_primary_address}
                />
              </div>
              <div>
                <TextField
                  sx={{ width: 400 }}
                  margin="normal"
                  multiline
                  rows={4}
                  name="employee_current_alt_address"
                  label="Current Alt. Address"
                  type="text"
                  id="employee_current_alt_address"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.employee_current_alt_address}
                />
              </div>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <Button
                  onClick={props.isEdit?handleUpdate:handleSubmit}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {props.isEdit ? "Update" : "Create"}
                </Button>
              </Box>

              <Grid container></Grid>
            </Box>
          </CardHOC>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  workplace: state.workplace,
});
const mapDispatchToProps = (dispatch: any) => ({
  openSnackBar: (state: {
    message: string;
    duration: number | undefined;
    severity: string;
  }) =>
    dispatch({
      type: "OPEN_SNACKBAR",
      payload: {
        message: state.message,
        duration: state.duration,
        severity: state.severity,
      },
    }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateEmployee)
);
