import React, { useEffect, useState } from "react";
import workspaceServices from "../../services/workspaceServices";
import CommonDataTable from "../common/CommonDataTable";
import commonStyle from "../../styles/commonStyle";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EmployeeAction from './EmployeeAction'
import {
  IconButton,
  Tooltip,
  TextField,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import Autocomplete from "@mui/material/Autocomplete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommonDialog from "../common/CommonDialog";
const WorkPlaceEmployees = (props: any) => {
  const { classes: commonClasses, cx } = commonStyle();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [workplacedata, setWorkplaceData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteEmpID,setDeleteEmpID]=useState(0)
  const [gridLoading, setGridLoading] = useState(false);
  const [workplaceoptions, setworkplaceoptions] = useState([]);
  const [selectedWorkplace, setselectedWorkplace] = useState<{ id?: number }>();
 const [openPopUp,setOpenPopUp]=useState(false)
  const navigate = useNavigate();

  function handleEditEmployee(id: number) {
    navigate(
      `/${props.workplace}/editemployee/${
        props.isPlatformAdmin ? selectedWorkplace?.id : props.workplaceid
      }/${id}`,
      { replace: true }
    );
  }
function handleViewDocuments(id:number) {
  navigate(
      `/${props.workplace}/viewdocuments/${
        props.isPlatformAdmin ? selectedWorkplace?.id : props.workplaceid
      }/${id}`,
      { replace: true }
    );
}
  function deleteWorkPlaceEmployee(id: number) {
    setDeleteEmpID(id);
setOpenPopUp(true);
  }

  function handleDeleteEmp() {
    let req = {
      workplace_id: props.isPlatformAdmin ? selectedWorkplace?.id : props.workplaceid,
      id:deleteEmpID
    };
    workspaceServices
      .deleteWorkPlaceEmployee(req)
      .then((resp) => {
        if (resp.data.statusCode === 201) {
          setOpenPopUp(false)
          props.openSnackBar({ message: "Employee deleted successfully" });
          if (props.isPlatformAdmin) {
      getWorkPlaces();
    } else {
      getWorkPlaceEmployees(props.workplaceid);
    }

        } else {
          props.openSnackBar({
            message: "Something went wrong! please try again",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        props.openSnackBar({
          message: "Something went wrong! please try again",
          severity: "error",
        });
      });
  }
  const buttons = [
    {
      type: "link",
      label: "Create Employee",
      link: `/${props.workplace}/createemployee/${
        props.isPlatformAdmin ? selectedWorkplace?.id : props.workplaceid
      }`,
      icon: AddBoxIcon,
      isDisabled: false,
    },
  ];
  const columns = [
    {
      name: "Name",
      cell: (item: any) => (
        <span>
          {`${item.employee_salutation} ${item?.employee_first_name} ${item?.employee_middle_name} ${item?.employee_last_name}`}{" "}
        </span>
      ),
      width: "15%",
    },
    {
      name: "Email",
      cell: (item: any) => <span>{item?.employee_email}</span>,
      width: "20%",
      wrap: true,
    },
    {
      name: "Contact",
      cell: (item: any) => <span>{item?.employee_mobile_number}</span>,
      width: "15%",
      wrap: true,
    },
    {
      name: "Address",
      cell: (item: any) => <span>{item?.employee_permanent_address}</span>,
      width: "20%",
      wrap: true,
    },
    {
      name: "Created date",
      cell: (item: any) => (
        <span>
          {format(new Date(item?.created_datetime), "yyyy-MM-dd hh:mm a")}
        </span>
      ),
      width: "20%",
    },
    {
      name: "Action",
      cell: (item: any) => (
        <EmployeeAction
        item={item}
        handleEditEmployee={handleEditEmployee}
        handleUploadDocuments={handleUploadDocuments}
        handleViewDocuments={handleViewDocuments}
        deleteWorkPlaceEmployee={deleteWorkPlaceEmployee}
         />
       
      ),
      width: "10%",
    },
  ];
  useEffect(() => {
    if (!props.isPlatformAdmin) {
      setselectedWorkplace({ id: props.workplaceid });
    }
    setGridLoading(true);
    if (props.isPlatformAdmin) {
      getWorkPlaces();
    } else {
      getWorkPlaceEmployees(props.workplaceid);
    }
  }, []);

  function handleUploadDocuments(id: number) {
    navigate(`/${props.workplace}/documentsupload/${id}`, { replace: true });
  }
  function handleChange(params: any) {
    if (params !== null) {
      setselectedWorkplace(params);
      getWorkPlaceEmployees(params.id);
    } else {
      setselectedWorkplace(workplaceoptions[0]);
      let wp: { id: number } = workplaceoptions[0];

      getWorkPlaceEmployees(wp.id);
    }
  }
  function getWorkPlaces() {
    let req = {
      perPage: 10000,
      page: 1,
    };
    workspaceServices
      .getWorkSpace(req)
      .then((resp) => {
        if (resp.status === 200) {
          setworkplaceoptions(resp.data.data.getWorkPlaces.rows);

          setselectedWorkplace(resp.data.data.getWorkPlaces.rows[0]);
          getWorkPlaceEmployees(resp.data.data.getWorkPlaces.rows[0].id);
        } else {
          setworkplaceoptions([]);
        }
        setGridLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setGridLoading(false);
      });
  }
  function getWorkPlaceEmployees(id: number) {
    setGridLoading(true);

    let req = {
      perPage: perPage,
      page: page,
      id: id,
    };
    apiCall(req);
  }
  function apiCall(req: any) {
    workspaceServices
      .getWorkPlaceEmployees(req)
      .then((resp) => {
        if (resp.status === 200) {
          setData(resp.data.data.getWorkPlaceEmployees.rows);
          setTotalRecords(resp.data.data.getWorkPlaceEmployees.totalCount);
        } else {
          setData([]);
          setTotalRecords(0);
        }
        setGridLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setGridLoading(false);
      });
  }
  function handleChangePage(pageNumber: number) {
    let req = {
      perPage: pageNumber,
      page: page,
      id: selectedWorkplace?.id,
    };
    apiCall(req);
  }
  function handleChangeRowsPerPage(rowCount: number) {
    setPage(1);
    setPerPage(rowCount);

    let req = {
      perPage: rowCount,
      page: 1,
      id: selectedWorkplace?.id,
    };
    apiCall(req);
  }
  return (
    <div className={cx(commonClasses.fullWidth)}>
      <div>
        {props.isPlatformAdmin && (
          <Autocomplete
            disablePortal
            value={selectedWorkplace !== undefined ? selectedWorkplace : {}}
            id="combo-box-demo"
            options={workplaceoptions}
            getOptionLabel={(option: any) => option.workplace_name}
            onChange={(event: any, newValue: any) => {
              handleChange(newValue);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select workplace"
                variant="outlined"
                size="small"
              />
            )}
          />
        )}
      </div>
      {!gridLoading && (
        <CommonDataTable
          columns={columns}
          data={data}
          title="Workplace Employee List"
          buttons={buttons}
          perPage={perPage}
          page={page}
          totalRecords={totalRecords}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          placeholder="Search Employee by Name, Email"
        />
      )}

      {
  openPopUp && 
  <CommonDialog 
      title="Delete Employee"
      showCancelBtn={true}
      cancelButtonName={"No"}
      showActionBtn={true}
      actionButtonName="Yes"
      cancelHandler={()=>setOpenPopUp(false)}
      actionHandler={handleDeleteEmp}
      >
Are you sure you want to delete this employee?
        </CommonDialog>
}

    </div>
  );
};
const mapStateToProps = (state: any) => ({
  workplace: state.workplace,
  isPlatformAdmin: state.isPlatformAdmin,
  workplaceid: state.workplaceid,
  role: state.role,
});
const mapDispatchToProps = (dispatch: any) => ({
  openSnackBar: (state: {
    message: string;
    duration: number | undefined;
    severity: string;
  }) =>
    dispatch({
      type: "OPEN_SNACKBAR",
      payload: {
        message: state.message,
        duration: state.duration,
        severity: state.severity,
      },
    }),
});
export default connect(mapStateToProps,mapDispatchToProps)(WorkPlaceEmployees);
