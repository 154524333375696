import { Box, Typography } from '@mui/material';
import React from 'react';

const FormTitle = (props:{title:string,subtiile?:string}) => {
    return (
        <Box sx={{marginBottom:"32px"}}>
            <Typography component="p" sx={{fontSize:"22px",color:"#000"}}>
                {props.title}
            </Typography>
            {
                props.subtiile && 
                <Typography component="p" sx={{fontSize:"14px",color:"#000"}}>
                {props.subtiile}
            </Typography>
            }
        </Box>
    );
};

export default FormTitle;
