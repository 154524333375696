import axios from "axios";
import {
    BASE_API_URL
} from "./Env";

const documentServices={
    saveDoumentInfo,
    deleteDoument
}

async function saveDoumentInfo(req) {


    return await axios({

        // Endpoint to send files
        url: `${BASE_API_URL}/platform/documents`,
        data: [{
            "document": {
                "crud": "C",
                "data": [
                    {
                      ...req
                    }
                ]
            }
        }],
        method: "POST",
        params: { 'x-auth-token': localStorage.getItem('token') },
        headers: { 'x-auth-token': localStorage.getItem('token') }
    }).then((res) => {
        return res
    }).catch((err) => {
        return err
    });
}

async function deleteDoument(req) {


    return await axios({

        // Endpoint to send files
        url: `${BASE_API_URL}/platform/documents`,
        data: [{
            "document": {
                "crud": "D",
                "data": [
                    {
                      ...req
                    }
                ]
            }
        }],
        method: "POST",
        params: { 'x-auth-token': localStorage.getItem('token') },
        headers: { 'x-auth-token': localStorage.getItem('token') }
    }).then((res) => {
        return res
    }).catch((err) => {
        return err
    });
}

export default documentServices;
