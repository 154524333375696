import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";

const commonComponentStyle = makeStyles()((theme: Theme) => {
  return {
    cardName: {
      fontSize: "20px",
      fontWeight: "500",
    },
    cardNumber: {
      fontSize: "32px",
      fontWeight: "700",
    },
    iconBox: {
      width: "50px",
      height: "50px",
      [theme.breakpoints.down("md")]: {
        width: "40px",
        height: "40px",
      },
      [theme.breakpoints.up("md")]: {
        width: "60px",
        height: "60px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "60px",
        height: "60px",
      },
    },
  };
});

export default commonComponentStyle;
