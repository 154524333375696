import { Theme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import React from "react";

const commonStyle = makeStyles()((theme:Theme) =>{
    return {
      height100v:{
        height:'100vh'
      },
      fullWidth:{
        width:'100%'
      },
       fullWidth70:{
        width:'70%'
      },
      alaignItemsCenter:{
        alignItems:"center"
      },
      justifyContentCenter:{
        justifyContent:'center'
      },
      justifyContentEnd:{
        justifyContent:'flex-end'
      },
      displayFlex: {
        display: "flex"
      },
      flexDirectionColumn:{
        flexDirection:"column"
      },
      padding0:{
        padding:'0'
      },
      padding8:{
        padding:'8px'
      },
      padding16:{
        padding:'16px'
      },
      paddingTop:{
        paddingTop:"10px"
      },
      paddingBottom:{
        paddingBottom:"10px"
      },
      paddingBottom32:{
        paddingBottom:"32px"
      },
      height55px:{
        height:"55px"
      },
      whiteSpaceNoWrap:{
        whiteSpace:"nowrap"
      },
      border:{
        border:`1px solid ${theme.palette.secondary.main}`,
      }
      }
})


export default commonStyle;
