import React, { useState, useEffect } from "react";
import withRouter from "../../containers/withRouter";
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    FormControl,
    Radio,
    FormControlLabel,
    FormLabel,
    Grid,
    RadioGroup,
    TextField,
} from "@mui/material";
import { CardHOC } from "../hocs/CardHOC";
import FormTitle from "../common/FormTitle";
import countryList from "../../utils/Contries.json";
import workspaceServices from "../../services/workspaceServices";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function ProfileForm(props: any) {
    const navigate = useNavigate();

    const [values, setValues] = useState({
        user_gender: "",
        user_first_name: "",
        user_middle_name: "",
        user_last_name: "",
        email_id: "",
        user_mobile_number: "",
        user_country: "",
        user_country_code: "",
        user_designation:"",
        user_password:""
    });
    const roles = [
        {
          name: "Admin",
          value: "Admin",
        },
        {
          name: "Normal",
          value: "Normal",
        },
      ];
    useEffect(() => {
        if(props.user!==undefined){
            console.log('props.user',props.user);

            setValues({
                user_gender: props.user.user_gender,
                user_first_name: props.user.user_first_name,
                user_middle_name: props.user.user_middle_name,
                user_last_name: props.user.user_last_name,
                email_id: props.user.email_id,
                user_mobile_number: props.user.user_mobile_number,
                user_country: props.user.user_country,
                user_country_code: props.user.user_country_code,
                user_designation:props.user.user_designation,
                user_password:""
            })
        }
        
        
    }, [props.user])
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setValues({
            ...values,
            [e.target.name]:e.target.value
        })
    }
    function handleCountryChange(e: any) {
        if (e !== null) {
            setValues({
                ...values,
                user_country: e.name,
                user_country_code: e.code,
            });
        } else {
            setValues({
                ...values,
                user_country: "",
                user_country_code: "",
            });
        }
    }

    const handleSubmit = () => {
        let req = {
            workplace_id: props.workplaceid,
            id:props?.params?.userid,
            user_gender: values.user_gender,
            user_first_name: values.user_first_name,
            user_middle_name: values.user_middle_name,
            user_last_name: values.user_last_name,
            email_id: values.email_id,
            user_mobile_number: values.user_mobile_number,
            user_country: values.user_country,
            user_country_code: values.user_country_code,
            user_name:props.userInfo.user_name,
            user_password:values.user_password,
            user_designation:values.user_designation

        };
        console.log(req);
        workspaceServices.updateUser(req).then((resp) => {
            if (resp.data.statusCode === 201) {
              props.openSnackBar({ message: "User updated successfully" });
              navigate(`/${props.workplace}/users`, { replace: true });

            } else {
              props.openSnackBar({
                message: "Something went wrong! please try again",
                severity: "error",
              });
            }
          })
          .catch((e) => {
            console.log(e);
            props.openSnackBar({
              message: "Something went wrong! please try again",
              severity: "error",
            });
          });
    };

    return (

        <div>

            <Box>
                {/* <Avatar sx={{width:"60px", height:"60px"}}>
  </Avatar> */}
            </Box>
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <TextField
                    sx={{ width: 200, mr: 2 }}
                    margin="normal"
                    required
                    name="user_first_name"
                    label="First Name"
                    type="text"
                    id="user_first_name"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={values.user_first_name}
                />
                <TextField
                    sx={{ width: 200, mr: 2 }}
                    margin="normal"
                    name="user_middle_name"
                    label="Middle Name"
                    type="text"
                    id="user_middle_name"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={values.user_middle_name}
                />
                <TextField
                    sx={{ width: 200 }}
                    margin="normal"
                    required
                    name="user_last_name"
                    label="Last Name"
                    type="text"
                    id="user_last_name"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={values.user_last_name}
                />
            </Box>
            <div>
                <FormControl sx={{ mt: 2 }}>
                  <FormLabel id="demo-employee_gender-label">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-employee_gender-label"
                    name="user_gender"
                    value={values.user_gender}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="Male"
                      control={<Radio />}
                      label="Male"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            <div>
                
                <TextField
                    sx={{ width: 400 }}
                    margin="normal"
                    required
                    name="user_designation"
                    label="Designation"
                    type="text"
                    id="user_designation"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={values.user_designation}
                />
            </div>
            <div>
                
                <TextField
                    sx={{ width: 400 }}
                    margin="normal"
                    required
                    name="email_id"
                    label="Email"
                    type="text"
                    id="email_id"
                    variant="outlined"
                    size="small"
                    disabled
                    value={values.email_id}
                />
            </div>
            <div>
                
                <TextField
                    sx={{ width: 400 }}
                    margin="normal"
                    required
                    name="user_password"
                    label="Password"
                    type="password"
                    id="user_password"
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    value={values.user_password}
                />
            </div>
            <div>
                <TextField
                    sx={{ width: 400 }}
                    margin="normal"
                    required
                    name="user_mobile_number"
                    label="Mobile Number"
                    type="text"
                    id="user_mobile_number"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={values.user_mobile_number}
                />
            </div>

            <div>
                <Autocomplete
                    disablePortal
                    // value={selectedsalutation}
                    size="small"
                    id="combo-box-demo"
                    options={countryList}
                    getOptionLabel={(option: any) => option.name}
                    onChange={(event: any, newValue: any) => {
                        handleCountryChange(newValue);
                    }}
                    sx={{ width: 400 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Country"
                            variant="outlined"
                            margin="normal"

                        />
                    )}
                />
            </div>



            <Box sx={{ width: "100%", textAlign: "right" }}>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Update
                </Button>
            </Box>

            <Grid container></Grid>

        </div>
    )
}


const mapStateToProps = (state: any) => ({
    workplace: state.workplace,
    workplaceid: state.workplaceid,
    userInfo:state.userInfo

});
const mapDispatchToProps = (dispatch: any) => ({
    openSnackBar: (state: {
        message: string;
        duration: number | undefined;
        severity: string;
    }) =>
        dispatch({
            type: "OPEN_SNACKBAR",
            payload: {
                message: state.message,
                duration: state.duration,
                severity: state.severity,
            },
        }),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileForm))