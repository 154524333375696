import  './assets/fonts/Manrope/Manrope-Regular.woff';
export const manropeLight = {
    fontFamily: 'Manrope-Light',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 300,
    src: `
      local('Manrope-light'),
      url('./assets/fonts/Manrope/Manrope-Light.woff') format('woff')
    `,
      };

  export const manropeRegular = {
    fontFamily: 'Manrope-Regular',
    fontStyle: 'normal',
    // fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      // local('Manrope-Regular'),
      url('./assets/fonts/Manrope/Manrope-Regular.woff) format('woff')
    `,
    };

  export const manropeSemiBold = {
    fontFamily: 'Manrope-SemiBold',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
      local('Manrope-SemiBold'),
      url('./assets/fonts/Manrope/Manrope-SemiBold.woff') format('woff')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  };

  export const manropeMedium= {
    fontFamily: 'Manrope-Medium',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
      local('Manrope-Medium'),
      url('./assets/fonts/Manrope/Manrope-Medium.woff') format('woff')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  };

  export const manropeBold= {
    fontFamily: 'Manrope-Bold',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
      local('Manrope-Bold'),
      url('./assets/fonts/Manrope/Manrope-Bold.woff') format('woff')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  };