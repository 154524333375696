import React,{useState} from 'react';
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  IconButton,
  Tooltip,
  TextField,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";

const EmployeeAction = (props:any) => {
        const {item,handleEditEmployee,handleUploadDocuments,handleViewDocuments,deleteWorkPlaceEmployee}=props;

     const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const ITEM_HEIGHT = 48;
    return (
        <Box sx={{ position: "relative" }}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem onClick={handleClose}>
              <span onClick={() => handleEditEmployee(item.id)}>
                Edit Employee
              </span>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <span onClick={() => handleUploadDocuments(item.id)}>
                Upload Documents
              </span>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <span onClick={() => handleViewDocuments(item.id)}>
                View Documents
              </span>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <span onClick={() => deleteWorkPlaceEmployee(item.id)}>Delete</span>
            </MenuItem>
          </Menu>
        </Box>
    );
};

export default EmployeeAction;
