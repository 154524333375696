import React from 'react';
import { Link } from 'react-router-dom';
// import logo from '../../assets/images/logo.86ff5f47.png'
import logo from '../../assets/images/Logo.svg'
import commonStyle from '../../styles/commonStyle';
import logo2 from '../../assets/images/logo.png';
const HeaderLogo = () => {
    const {classes:commonClasses,cx}=commonStyle()
    return (
        <div className={cx(commonClasses.padding16)}>
            <Link to={"/"}> <img src={logo2} className={cx(commonClasses.displayFlex,commonClasses.fullWidth)} /></Link>
        </div>
    );
};

export default HeaderLogo;
