import React, { useState,useEffect } from "react";
import withRouter from "../../containers/withRouter";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  TextField,
} from "@mui/material";
import { CardHOC } from "../hocs/CardHOC";
import FormTitle from "../common/FormTitle";
import countryList from "../../utils/Contries.json";
import workspaceServices from "../../services/workspaceServices";
import { connect } from "react-redux";
import ProfileForm from "./ProfileForm";

const Profile = (props: any) => {
  const [user, setUser] = useState<any>();

  useEffect(()=>{
    getWorkplaceUserByID(props?.params?.userid);

  },[])
  function getWorkplaceUserByID(id: number) {
    let req = {
      id: props?.params?.id,
      userid: id,
    };
    workspaceServices
      .getWorkplaceUserByIDNew(req)
      .then((resp) => {
        if (resp.data.statusCode === 200 && resp.data.success === true) {
          let respData = resp.data.data.getUserProfile.rows[0];
          setUser(respData);
        } else {
          setUser(undefined);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  return (
    <Grid container spacing={2}>
    <Grid item xs={12} md={9} xl={9}>
    <CardHOC>
    <FormTitle title="User Profile" />
    <Box component="div">
   <ProfileForm user={user} />
    </Box>
          </CardHOC>
          </Grid>
      </Grid>
  );
};
const mapStateToProps = (state: any) => ({
  workplace: state.workplace,
  workplaceid: state.workplaceid,
});
const mapDispatchToProps = (dispatch: any) => ({
  openSnackBar: (state: {
    message: string;
    duration: number | undefined;
    severity: string;
  }) =>
    dispatch({
      type: "OPEN_SNACKBAR",
      payload: {
        message: state.message,
        duration: state.duration,
        severity: state.severity,
      },
    }),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
