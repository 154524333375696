import React, { DragEvent, useEffect, useState } from "react";
import withRouter from "../../containers/withRouter";
import {
  IconButton,
  Tooltip,
  TextField,
  Menu,
  MenuItem,
  Box,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import workspaceServices from "../../services/workspaceServices";
import { connect } from "react-redux";
import AWS from "aws-sdk";
import { CardHOC } from "../hocs/CardHOC";
import UploadFile from "../common/UploadFile";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import countryList from "../../utils/Contries.json";
import dayjs, { Dayjs } from "dayjs";
import { format, toDate } from "date-fns";
import documentServices from "../../services/documentServices";

type docProps = {
  document_unique_number: number | string;
  document_issue_date: string | undefined;
  document_exp_date: string | undefined;
  employee_country: string;
  employee_country_code: string;
};
const DocumentUpload = (props: any) => {
  const [empid, setEmpID] = useState<number>();
  const [issueDate, setIssueDate] = React.useState<Date | null>(null);
  const [values, setValues] = useState<docProps>({
    document_unique_number: "",
    document_issue_date: "",
    document_exp_date: "",
    employee_country: "",
    employee_country_code: "",
  });

  const fileTypes = ["JPEG", "PNG", "JPG"];
  const [documentCatergories, setDocumentCatergories] = useState([]);
  const [selectedCatergory, setSelectedCatergory] = useState<{
    option: string;
    value: string;
  }>();
  const [progress, setProgress] = useState(0);

 // Initialize the Amazon Cognito credentials provider
  AWS.config.region = "ap-south-1"; // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "ap-southeast-1:a87963be-79e6-4907-889f-873e5055a3c9",
  });
  const SESConfig = {
    apiVersion: "2014-11-12",
    accessKeyId: "AKIAWLMK6SZ5EBLDDFGY",
    accessSecretKey: "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",
    region: "ap-south-1",
  };
  AWS.config.update(SESConfig);
  const myBucket = new AWS.S3({
    params: { Bucket: "expatrax-document" },
    region: "ap-south-1",
  });
  useEffect(() => {
    setEmpID(props?.params?.empid);
    fetchDocCategories();
  }, []);

  useEffect(() => {
    // console.log("selectedCatergory", selectedCatergory);
  }, [selectedCatergory]);

  function fetchDocCategories() {
    let req={
      id:props.workplaceid,
      perPage:100,
      page:1
    }
    workspaceServices
      .getWorkPlaceDocumentCategories(req)
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          setDocumentCatergories(
            resp.data.data.getWorkPlaceDocumentCategories.rows
          );
        }
      });
  }
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  }
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (op: any) => op.name,
  });

  function handleSubmit() {
    /*
     var d = new Date();
      var n = d.getTime();
      const ext = uploadedFiles[0].name?.split(".").pop();
      let filename = selectedCatergory&&selectedCatergory?.value?.replaceAll(' ','_') + n + new Date().getTime() + "." + ext;
    const params = {
        ACL: "public-read",
        Body: uploadedFiles[0],
        Bucket: "expatrax-document",
        Key: ""+filename,
      };

      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err, data) => {
          console.log(err);
          
        });
        */
    if (
      (values.document_exp_date !== "Invalid Date" &&
      values.document_exp_date !== undefined &&
      values.document_exp_date !== "") &&
      (values.document_issue_date !== "Invalid Date" &&
      values.document_issue_date !== undefined &&
      values.document_issue_date !== "" )&&
      values.document_unique_number !== ""
    ) {
      const req = {
        workplace_id: props.workplaceid,
        object_id: empid,
        object_type: "User",
        document_type: selectedCatergory?.value,
        document_name: selectedCatergory?.value,
        document_unique_number: values.document_unique_number,
        document_desc: "",
        document_status: "Created",
        document_issue_date: values.document_issue_date,
        document_exp_date: values.document_exp_date,
        document_file_path: "",
      };
      documentServices
        .saveDoumentInfo(req)
        .then((resp) => {
          if (resp.data.statusCode === 201) {
            props.openSnackBar({ message: "Document info saved successfully" });
            setValues({
    document_unique_number: "",
    document_issue_date: "",
    document_exp_date: "",
    employee_country: "",
    employee_country_code: "",
  })
  setSelectedCatergory(undefined)
          } else {
            props.openSnackBar({
              message: "Something went wrong! please try again",
              severity: "error",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          props.openSnackBar({
            message: "Something went wrong! please try again",
            severity: "error",
          });
        });
    }
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8} xl={8}>
        <CardHOC>
          <Box sx={{ padding: "32px" }}>
            <Typography variant="h6" sx={{ marginBottom: "16px" }}>
              Upload Documents
            </Typography>
            <Box>
              <Autocomplete
                sx={{ marginBottom: "48px" }}
                disablePortal
                id="combo-box-demo"
                size="small"
                options={documentCatergories}
                getOptionLabel={(option: any) => option?.option}
                // sx={{ width: 300 }}
                onChange={(event: any, newValue: any | null) => {
                  setSelectedCatergory(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Document to upload" />
                )}
              />
              {selectedCatergory !== undefined && (
                <>
                  <Box>
                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            sx={{ marginBottom: "16px", width: "49%" }}
                            id="outlined-basic"
                            name="document_unique_number"
                            value={values.document_unique_number}
                            label="Document Number *"
                            variant="outlined"
                            onChange={handleChange}
                            size="small"
                            error={values.document_unique_number === ""}
                            helperText={
                              values.document_unique_number !== ""
                                ? ""
                                : "Document number required."
                            }
                          />
                          <Autocomplete
                            disablePortal
                            // value={selectedsalutation}
                            size="small"
                            filterOptions={filterOptions}
                            id="combo-box-demo"
                            options={countryList}
                            getOptionLabel={(option: any) => option.name}
                            onChange={(event: any, newValue: any) => {
                              setValues({
                                ...values,
                                employee_country: newValue?.name,
                                employee_country_code: newValue?.code,
                              });
                            }}
                            sx={{ marginBottom: "16px", width: "49%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Country"
                                variant="outlined"
                              />
                            )}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              // onError={(newError) => (newError)}

                              format="YYYY-MM-DD"
                              sx={{ marginBottom: "16px", width: "49%" }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  name: "document_issue_date",
                                   error:
                                    values.document_issue_date ===
                                      "Invalid Date" ||
                                    values.document_issue_date === undefined ||
                                    values.document_issue_date === "",
                                  helperText:
                                    values.document_issue_date !== undefined &&
                                    values.document_issue_date !== "" &&
                                    values.document_issue_date !== "Invalid Date"
                                      ? ""
                                      : "Date Required",
                                },
                              }}
                              label="Issue Date"
                              maxDate={dayjs()}
                              onChange={(newValue: Dayjs | null) => {
                                setValues({
                                  ...values,
                                  document_issue_date:
                                    newValue?.format("YYYY-MM-DD"),
                                });
                              }}
                            />
                          </LocalizationProvider>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format="YYYY-MM-DD"
                              sx={{ marginBottom: "16px", width: "49%" }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  name: "document_exp_date",
                                  error:
                                    values.document_exp_date ===
                                      "Invalid Date" ||
                                    values.document_exp_date === undefined ||
                                    values.document_exp_date === "",
                                  helperText:
                                    values.document_exp_date !== undefined &&
                                    values.document_exp_date !== "" &&
                                    values.document_exp_date !== "Invalid Date"
                                      ? ""
                                      : "Date Required",
                                },
                              }}
                              // value={dayjs(values.document_exp_date)}
                              label="Expiry date"
                              minDate={dayjs()}
                              onChange={
                                (newValue: Dayjs | null) => {
                                  setValues({
                                    ...values,
                                    document_exp_date:
                                      newValue?.format("YYYY-MM-DD"),
                                  });
                                }

                                // setIssueDate(newValue)
                              }
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                    </Box>
                    {false && (
                      <Box>
                        <Box sx={{ marginBottom: "16px" }}>
                          <Typography component="p">Document File</Typography>
                          <UploadFile
                            getFiles={setUploadedFiles}
                            dropZoneObj={{
                              maxFiles: 1,
                              accept: {
                                "image/png": [".png", ".jpg", ".jpeg"],
                                "application/pdf": [".pdf"],
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Box sx={{ textAlign: "right" }}>
                    <Button variant="contained" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </CardHOC>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state: any) => ({
  isAuthenticatedUser: state.isAuthenticatedUser,
  workplaceid: state.workplaceid,
});
const mapDispatchToProps = (dispatch: any) => ({
  openSnackBar: (state: {
    message: string;
    duration: number | undefined;
    severity: string;
  }) =>
    dispatch({
      type: "OPEN_SNACKBAR",
      payload: {
        message: state.message,
        duration: state.duration,
        severity: state.severity,
      },
    }),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DocumentUpload)
);
