// @flow
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid";
import PersonIcon from '@mui/icons-material/Person';

import { CardHOC } from "../hocs/CardHOC";
import commonComponentStyle from "./commonComponentStyle";
import commonStyle from "../../styles/commonStyle";
import { RoundIcon } from "./RoundIcon/RoundIcon";
export const CommonCardWithIcon = (props: any) => {
  const {classes,cx}=commonComponentStyle()
  const {classes:commonClasses}=commonStyle();
  return (
    <CardHOC classes={cx(commonClasses.padding16)}>
      <Grid container alignItems={"center"}>
        <Grid item xs={8} md={8} xl={8}>
          <Typography className={cx(classes.cardName)}>{props.title}</Typography>
          <Typography className={cx(classes.cardNumber)}>{props.number}</Typography>
        </Grid>
        <Grid item xs={4} md={4} xl={4}>
          <RoundIcon Icon={props.icon} color={props.color} />
        </Grid>
      </Grid>
    </CardHOC>
  );
};
