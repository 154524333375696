import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import workspaceServices from "../../services/workspaceServices";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import withRouter from "../../containers/withRouter";
import { CardHOC } from "../hocs/CardHOC";
import FormTitle from "../common/FormTitle";
import { userInfo } from "os";
import ProfileForm from "./ProfileForm";

type User = {
  workplace_role: string;
  workplace_id: number;
  user_name: string;
  email_id: string;
};
function CreateUser(props: any) {
      const navigate = useNavigate();

  const [values, setValues] = useState({
    email_id: "",
    password: "",
    username: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const roles = [
    {
      name: "Admin",
      value: "Admin",
    },
    {
      name: "Normal",
      value: "Normal",
    },
  ];
  const [workplace_id, setWorkPleaceID] = useState("");
  const [userid, setUserID] = useState("");

  const [workplace_role, setWorkPleaceRole] = useState(roles[0]);

  useEffect(() => {
    if (props?.params?.id !== undefined) {
      setWorkPleaceID(props?.params?.id);
    }

    if (props?.params?.userid !== undefined) {
      setUserID(props?.params?.userid);
      getWorkplaceUserByID(props?.params?.userid);
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, []);
  function getWorkplaceUserByID(id: number) {
    let req = {
      id: props?.params?.id,
      userid: id,
    };
    workspaceServices
      .getWorkplaceUserByIDNew(req)
      .then((resp) => {
        if (resp.data.statusCode === 200 && resp.data.success === true) {
          let respData = resp.data.data.getUserProfile.rows[0];
          setUser(respData);
          let role = roles.find(
            (item) => item.name === respData.workplace_role
          );
          setWorkPleaceRole(role || roles[0]);
          setValues({
            email_id: respData.email_id,
            password: "",
            username: respData.user_name,
          });
        } else {
          setUser(undefined);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function handleChange(e: any) {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  }
  function handleRoleChange(e: any) {
    setWorkPleaceRole(e);
  }

  const handleSubmit = () => {
    workspaceServices
      .createWorkPlaceUser({
        email_id: values.email_id,
        password: values.password,
        workplace_id: workplace_id,
        workplace_role: workplace_role.value,
      })
      .then((resp) => {
        if (resp.data.statusCode === 201) {
          setValues({
            email_id: "",
            password: "",
            username: "",
          });
          setWorkPleaceRole(roles[0]);
          props.openSnackBar({ message: "User created successfully" });
        } else {
          props.openSnackBar({
            message: "Something went wrong! please try again",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        props.openSnackBar({
          message: "Something went wrong! please try again",
          severity: "error",
        });
      });
  };
  const handleUpdate = () => {
    workspaceServices
      .updateWorkPlaceUser({
        username: values.username,
        id: props?.params?.userid,
        email_id: values.email_id,
        password: values.password,
        workplace_id: workplace_id,
        workplace_role: workplace_role.value,
      })
      .then((resp) => {
        if (resp.data.statusCode === 201) {
          setWorkPleaceRole(roles[0]);
          props.openSnackBar({ message: "User updated successfully" });
        navigate(`/${props.workplace}/users`, { replace: true });

        } else {
          props.openSnackBar({
            message: "Something went wrong! please try again",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        props.openSnackBar({
          message: "Something went wrong! please try again",
          severity: "error",
        });
      });
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} xl={9}>
          <CardHOC>
            <FormTitle title={`${isEdit ? "Edit" : "Create new"} user`} />
            {
              !isEdit?
              <Box component="div">
              <div>
                <Autocomplete
                  disablePortal
                  value={workplace_role}
                  size="small"
                  id="combo-box-demo"
                  options={roles}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(event: any, newValue: any) => {
                    handleRoleChange(newValue);
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="User role"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              {isEdit && (
                <div>
                  <TextField
                    sx={{ width: 300 }}
                    margin="normal"
                    required
                    name="username"
                    label="Username"
                    type="text"
                    id="username"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={values.username}
                  />
                </div>
              )}

              <div>
                <TextField
                  sx={{ width: 300 }}
                  margin="normal"
                  required
                  name="email_id"
                  label="User email"
                  type="text"
                  id="email_id"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.email_id}
                />
              </div>
              <div>
                <TextField
                  sx={{ width: 300 }}
                  margin="normal"
                  required
                  name="password"
                  label="Password"
                  type="text"
                  id="password"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.password}
                />
              </div>
              <div>
                <Button
                  onClick={isEdit ? handleUpdate : handleSubmit}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {isEdit ? "Update" : "Create"}
                </Button>
              </div>

              <Grid container></Grid>
            </Box>
             
             :
             <ProfileForm from="usergrid" user={user} />

            }
            
          </CardHOC>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  workplace: state.workplace,
  workplaceid: state.workplaceid,
});
const mapDispatchToProps = (dispatch: any) => ({
  openSnackBar: (state: {
    message: string;
    duration: number | undefined;
    severity: string;
  }) =>
    dispatch({
      type: "OPEN_SNACKBAR",
      payload: {
        message: state.message,
        duration: state.duration,
        severity: state.severity,
      },
    }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateUser)
);
