import ENV from "./env.json";


const env = process.env.REACT_APP_ENV;
const config = {
  production: ENV.prod,
  development: ENV.dev,
  test: ENV.test,
  qa: ENV.stage
};

export default config['production'];
