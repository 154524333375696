import { createStyles,Theme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import React from "react";

const menuStyle = makeStyles()((theme:any) =>{
    return {
        diplayFlex: {
          display: "flex"
        },
        menuBg:{
            background:theme.palette.secondary.main,
            minWidth:"215px",
            height:"100%"
          },
        menuExpandIcon:{
            color:'#fff'
        },
        menuLink:{
            padding:"10px 16px",
            borderRadius:"4px",
            '& .MuiListItemIcon-root':{
                color:"#fff",
                minWidth: "24px",
                height: "24px"
            },
            '&:hover':{
                background:`${theme.palette.primary.main}60`
            }
        }
      }
})


export default menuStyle;
