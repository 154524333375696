// reducers.js
const initialState = {
  isAuthenticatedUser: false,
  isPlatformAdmin:false,
  role:"",
  workplace: "",
  userInfo: [],
  workplaceid: 0,
  snackbar: {
    openSnackbar: false,
    message: "",
    duration: 6000,
    severity: "",
  },
};

const rootReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_AUTHENTICATED":
      return {
        ...state,
        isAuthenticatedUser: action?.payload?.isAuthenticatedUser,
        workplace: action?.payload?.workplace,
        workplaceid: action?.payload?.workplaceid,
        userInfo: action?.payload?.userInfo,
        isPlatformAdmin:action?.payload?.isPlatformAdmin,
        role:action?.payload?.role
      };
    case "OPEN_SNACKBAR":

      let tempPayload = action?.payload;
      return {
        ...state,
        snackbar: {
          openSnackbar: true,
          message: tempPayload.message,
          duration: tempPayload.duration ? tempPayload.duration : 6000,
          severity: tempPayload.severity,
        },
      };
    case "CLOSE_SNACKBAR":
      return {
        ...state,
        snackbar: {
          openSnackbar: false,
          message: "",
          duration: 6000,
          severity: "",
        },
      };

    default:
      return {
        ...state,
        snackbar: {
          openSnackbar: false,
          message: "",
          duration: 6000,
          severity: "",
        },
      };
  }
};

export default rootReducer;
