import { createStore, applyMiddleware, compose, } from 'redux';
import rootReducer from './reducers'; // Your root reducer
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";

const persistConfig = {
    key: "root",
    storage,
    blacklist: []
};
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;
let store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
);

export default store;