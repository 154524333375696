// @flow 
import * as React from 'react';
import Box from '@mui/material/Box';
import commonComponentStyle from '../commonComponentStyle';
export const RoundIcon = (props: any) => {
    const {classes,cx}=commonComponentStyle();
    const {color,Icon}=props;
    return (
        <Box className={classes.iconBox} sx={{color:color,border:`1px solid ${color}`,borderRadius:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
            <Icon sx={{width:"40px",height:"40px",}} />
        </Box>
    );
};
