import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import loginServices from "../services/loginServices";
import { Skeleton } from "@mui/material";
import commonStyle from "../styles/commonStyle";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import HeaderLogo from "./Header/HeaderLogo";
import logo2 from "../assets/images/logo.png";
import { CardHOC } from "./hocs/CardHOC";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://expatrax.com/">
        Expatrax
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

function SignIn(props: any) {
  const cookies = new Cookies();

  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const { classes: commonClasses } = commonStyle();
  const navigate = useNavigate();
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const usertoken: string = queryParameters.get("token")!;
    const verify: string = queryParameters.get("verify")!;
    if (verify === "true") {
      setToken(usertoken);
      localStorage.setItem("token", usertoken);
      navigate("/", { replace: true });
    }
    setLoading(false);
  }, []);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = "" + data.get("email");
    let password = "" + data.get("password");

    loginServices
      .login(email, password)
      .then((resp) => {
        if (resp.data.statusCode === 201 && resp.data.data.loggedIn) {
          localStorage.setItem("userinfo", JSON.stringify(resp.data.data.user));
          localStorage.setItem("token", resp.data.data.token);
          let workplacename =
            resp.data.data.user.defaultWorkplace.workplace_name;
          const workplaceID = resp.data.data.user.defaultWorkplace.id;
          props.setIsAuthenticated(
            true,
            workplacename,
            workplaceID,
            resp.data.data.user,
            resp.data.data.user.platform_admin!=="N",
resp.data.data.user.defaultWorkplace.workplace_role
          );
          cookies.set("isAuthenticatedUser", true);
          navigate(`/${workplacename}/`, { replace: true });
        } else {
          localStorage.removeItem("userinfo");
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);

        setLoading(false);
      });
  };

  return (
    <Grid container className={commonClasses.height100v}>
      <Grid
        item
        xs={12}
        md={6}
        xl={6}
        sx={{ background: "#1F290F" }}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <img src={logo2} alt="logo" />
      </Grid>
      <Grid
        md={6}
        xl={6}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <CardHOC>
          <Box
            sx={{
              width: "90%",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "30px 30px",
            }}
          >
            {loading ? (
              <>
                <Box sx={{width:"400px"}}>
                  <Typography variant="h6">
                    <Skeleton animation="wave" width="100%" />
                  </Typography>
                  
                  <Skeleton animation="wave"  height={67} />
                  <Skeleton animation="wave"  height={67} />
                 <Box sx={{display:"flex",justifyContent:"end"}}>
                  <Skeleton animation="wave" width={130} height={67} />

                 </Box>
                </Box>
              </>
            ) : (
              <>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ fontWeight: "500", width: "100%", textAlign: "left" }}
                >
                  Welcome back
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    size="small"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    size="small"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                  <Box sx={{ width:"100%", textAlign: "right" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2, width: "130px" }}
                    >
                      Sign In
                    </Button>
                  </Box>

                  <Grid container>
                    {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
                    {/* <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        </CardHOC>
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  setIsAuthenticated: (
    isAuthenticatedUser: boolean,
    workplace: string | undefined,
    workplaceid: number | undefined,
    userInfo: any,
    isPlatformAdmin:boolean,
    role:string
  ) =>
    dispatch({
      type: "SET_AUTHENTICATED",
      payload: {
        isAuthenticatedUser: isAuthenticatedUser,
        workplace: workplace,
        workplaceid: workplaceid,
        userInfo: userInfo,
isPlatformAdmin:isPlatformAdmin,
role:role
      },
    }),
});
export default connect(null, mapDispatchToProps)(SignIn);
