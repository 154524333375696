import React from 'react';
import RegistrationForm from '../components/Registration/RegistrationForm';

const Registration = () => {
    return (
        <div>
            <RegistrationForm />
        </div>
    );
};

export default Registration;