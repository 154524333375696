import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

function PrivateRoute(props:any) {
  const cookies=new Cookies();
    // const user = useSelector((state) => state.user);
    let location = useLocation();
  
    if(!props.isAuthenticatedUser) {
        return <Navigate to="/signin" state={{ from: location}} replace />
    }
 return props.children

};
const mapStateToProps = (state: any) => ({
  isAuthenticatedUser: state.isAuthenticatedUser,
});
export default connect(mapStateToProps)(PrivateRoute);
